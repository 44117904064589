import { Maybe, Nullable } from 'Consts/types';
import PubNub from 'pubnub';
const DEBUG: boolean = false;

export class PubNubService {
  private readonly enabled: boolean = false;
  private static pubNubInstance: Nullable<PubNubService>;
  private pubnubObj: Maybe<PubNub>;

  public static getInstance(
    config?: PubNub.PubnubConfig & { uuid: string },
    token?: string
  ): Nullable<PubNubService> {
    if (this.pubNubInstance) {
      if (this.pubNubInstance?.pubnubObj?.getUUID() !== config?.uuid) {
        console.error('PubNub instance incorrect for wrong location');
        this.pubNubInstance.destroy();
        this.pubNubInstance = null;
      }
    }
    return (
      this.pubNubInstance ||
      (config ? (this.pubNubInstance = new this(config, token)) : null)
    );
  }

  private constructor(
    config: PubNub.PubnubConfig & { uuid?: string },
    token?: string
  ) {
    if (DEBUG) console.log('pubNub constructor');
    if (config) {
      this.pubnubObj = new PubNub(config);
      if (token) {
        this.pubnubObj.setToken(token);
      }
      this.enabled = true;
    }
  }
  private destroy() {
    this.pubnubObj?.unsubscribeAll();
  }
  public addListener(
    channelsToAdd: string[],
    callbackFN: (data: PubNub.SignalEvent) => void
  ): void {
    if (DEBUG) console.log('PubNub addListener,', channelsToAdd);
    if (this.enabled) {
      if (this.pubnubObj) {
        const listenerParams = {
          status: (status: PubNub.StatusEvent) => {
            if (DEBUG) console.log('Status received, ', status);
            if (
              status.operation === 'PNSubscribeOperation' &&
              status.category === 'PNConnectedCategory'
            ) {
              if (DEBUG) console.log('PubNub subscribed and connected: ');
            }
          },
          signal: (data: PubNub.SignalEvent) => {
            if (DEBUG) console.log('Signal data received,', data);
            callbackFN(data);
          },
        };

        // Setup listeners
        if (this.pubnubObj) {
          this.pubnubObj.addListener(listenerParams);
          this.pubnubObj.subscribe({
            channels: channelsToAdd,
          });
        }
      } else {
        if (DEBUG) console.log('no pubnub object');
      }
    } else {
      if (DEBUG) console.log('not enabled');
    }
  }
}
