import React, {
  FunctionComponent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';

import styles from './style.module.css';
import Card from 'UI/Elements/Card';
import { OpenTriggerEventType, Person, ZoneDevice } from 'Consts/types';
import { IconNames } from 'UI/Elements/Icon';
import Avatar from 'UI/Molecules/Avatar';
import useEmployees from 'State/hooks/useEmployees';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import StandardListItem from 'UI/Components/Lists/List standard';
import AlertModal from 'UI/Elements/Modals/Alerts';
import Alert from 'UI/Components/Alert';
import { BUTTON_THEMES } from 'UI/Elements/Button';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import useDevices from 'State/hooks/useDevices';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import AddEmployeeSidePanel from 'UI/Reusable/AddEmployee/AddEmployee';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';

type AssignDeviceSidePanelProps = {
  device: ZoneDevice[];
};

type EmployeeAvatarProps = {
  employee: Person;
  onClick: (person: Person, assigned: boolean) => void;
  device: ZoneDevice[];
};

const EmployeeAvatar: FunctionComponent<EmployeeAvatarProps> = ({
  onClick,
  employee,
  device,
}) => {
  const isAssigned = useMemo(() => {
    if (!device) {
      return;
    }
    if (employee.id === device[0].personId) {
      return true;
    }
    return false;
  }, [device, employee]);

  const handleOnClick = useCallback(
    () => onClick(employee, isAssigned!),
    [employee, onClick, isAssigned]
  );

  return (
    <Avatar
      person={employee}
      isChecked={isAssigned}
      isDesaturated={!device[0].personId ? false : !isAssigned}
      onClick={handleOnClick}
    />
  );
};

const AssignDevice: FunctionComponent<AssignDeviceSidePanelProps> = ({
  device,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const employees = useEmployees();
  const { allDevices } = useDevices();
  const networkAccessData = useNetworkAccess();
  const { closeSidepanel, setContent } = useSidepanel();
  const [assignDeviceNow, setAssignDeviceNow] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Person>();
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const [unAssignModalOpen, setUnAssignModalOpen] = useState<boolean>(false);
  const devices = allDevices.data;

  const deviceIsNotApproved =
    device[0].networkAccessMode === 'auto' ||
    device[0].networkAccessMode === 'blocked';

  const networkIsInPurgatoryMode =
    (device[0].networkId === 'default' &&
      networkAccessData?.data?.default?.purgatory) ||
    (device[0].networkId === 'employee' &&
      networkAccessData?.data?.employee?.purgatory);

  const employeeLoginIsAvailableToCustomer = useSelector(
    selectors.customerSupportConfigurations
  ).data?.customerFeatureEnablement.employeeOnboardingCaptivePortalEnabled;
  const employeeLoginToggleIsEnabled = useSelector(
    selectors.settings.employeeLogin
  ).data?.databaseData.enabled;
  const employeeLoginSupportedInHardware = useSelector(
    selectors.locationCapabilities
  ).data?.state.capabilities?.captivePortalV2;

  const employeeLoginFeatureIsSupportedAndEnabled = useMemo(() => {
    return (
      employeeLoginIsAvailableToCustomer &&
      employeeLoginToggleIsEnabled &&
      employeeLoginSupportedInHardware
    );
  }, [
    employeeLoginIsAvailableToCustomer,
    employeeLoginToggleIsEnabled,
    employeeLoginSupportedInHardware,
  ]);

  const assignedDevices = useMemo(() => {
    if (!selectedEmployee) {
      return;
    }
    return (
      devices
        ?.filter((device) => device.personId === selectedEmployee.id)
        .map((device) => device.mac) || []
    );
  }, [devices, selectedEmployee]);

  useEffect(() => {
    if (assignDeviceNow) {
      assignDevice();
    }
    setAssignDeviceNow(false);
  }, [assignDeviceNow]);

  const handleAssignModalShow = useCallback(() => {
    setAssignModalOpen(true);
  }, []);

  const handleAssignModalHide = useCallback(() => {
    setAssignModalOpen(false);
  }, []);

  const handleUnAssignModalShow = useCallback(() => {
    setUnAssignModalOpen(true);
  }, []);

  const handleUnAssignModalHide = useCallback(() => {
    setUnAssignModalOpen(false);
  }, []);

  const handleAddEmployee = useCallback(
    (ev: React.MouseEvent) => {
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<AddEmployeeSidePanel selectedDevices={device} />);
    },
    [setContent, device]
  );

  const assignDevice = useCallback(() => {
    setAssignModalOpen(false);

    if (!selectedEmployee || !assignedDevices) {
      return;
    }

    assignedDevices.push(device[0].mac);

    dispatch(
      actions.employee.assignDeviceToEmployee(
        selectedEmployee?.id,
        assignedDevices
      )
    );

    dispatch(
      actions.employee.assignPrimaryDeviceToEmployee(
        selectedEmployee?.id,
        assignedDevices[0]
      )
    );

    dispatch(
      actions.ui.miniBanner.notify({
        label: t('devices.assignDeviceZone.newPersonAssigned', {
          employee: selectedEmployee.nickname,
        }),
        state: 'set',
      })
    );

    closeSidepanel();
  }, [selectedEmployee, device, dispatch, devices]);

  const reassignDevice = useCallback(
    (newEmployee: { id: string; nickname: string }) => {
      if (!selectedEmployee || !newEmployee || !assignedDevices) {
        return;
      }

      if (newEmployee.id === device[0]?.personId) {
        const filteredDevices = assignedDevices.filter((mac) => {
          return mac !== device[0].mac;
        });

        dispatch(
          actions.employee.assignDeviceToEmployee(
            selectedEmployee.id,
            filteredDevices
          )
        );

        dispatch(
          actions.ui.miniBanner.notify({
            label: t('devices.assignDeviceZone.unassigned', {
              employee: selectedEmployee.nickname,
            }),
            state: 'set',
          })
        );
      } else {
        const newAssignedDevices = [...assignedDevices, device[0].mac];

        dispatch(
          actions.employee.assignDeviceToEmployee(
            newEmployee.id,
            newAssignedDevices
          )
        );
        dispatch(
          actions.ui.miniBanner.notify({
            label: t('devices.assignDeviceZone.newPersonAssigned', {
              employee: selectedEmployee.nickname,
            }),
            state: 'set',
          })
        );
      }

      closeSidepanel();
    },
    [selectedEmployee, device, dispatch, devices]
  );

  const handleReassignButtonClick = () => {
    const newEmployee = {
      id: selectedEmployee?.id || 'undefined id',
      nickname: selectedEmployee?.nickname || 'undefined nickname',
    };

    newEmployee.id && newEmployee.nickname && reassignDevice(newEmployee);
  };

  const handleSelectEmployeeToAssign = useCallback(
    (employee: Person, isAssigned: boolean) => {
      setSelectedEmployee(employee);
      if (device[0].personId) {
        handleUnAssignModalShow();
      } else if (!isAssigned) {
        if (deviceIsNotApproved && networkIsInPurgatoryMode) {
          handleAssignModalShow();
        } else {
          setAssignDeviceNow(true);
        }
      }
    },
    [handleUnAssignModalShow, handleAssignModalShow, assignDevice, device]
  );

  if (!employees?.data) {
    return null;
  }

  const sortedEmployees = () =>
    (employees.data &&
      employees.data
        ?.filter((employee: Person) => employee.id)
        .sort((a, b) => a.nickname.localeCompare(b.nickname))) ||
    [];

  return (
    <SimpleSidePanel title={t('devices.assignDeviceZone.assignDeviceTo')}>
      <SidePanelContentWrapper>
        <Card>
          <div className={styles.employeeRow}>
            {sortedEmployees().map((employee, i) => (
              <EmployeeAvatar
                employee={employee}
                onClick={handleSelectEmployeeToAssign}
                device={device}
                key={employee.id}
              />
            ))}
          </div>
        </Card>
        {!employeeLoginFeatureIsSupportedAndEnabled && (
          <Card noBottomPadding>
            <StandardListItem
              L2Props={{
                label: t('devices.assignDeviceZone.assignNewPerson'),
              }}
              RProps={{
                icon1Props: { name: IconNames.AddPerson },
              }}
              onClick={handleAddEmployee}
              tabIndex={0}
              ariaLabel={t('devices.assignDeviceZone.assignNewPerson')}
            />
          </Card>
        )}

        <AlertModal isOpen={assignModalOpen} onClose={handleAssignModalHide}>
          <Alert
            topProps={{
              label: t('devices.modal.assignDeviceModal.label'),
              paragraph:
                t('devices.modal.assignDeviceModal.paragraph') ||
                'By assigning you will also approve this device',
              className: styles.modalHeader,
            }}
            bottomProps={{
              button1Props: {
                label:
                  t('devices.modal.assignDeviceModal.iUnderstand') ||
                  'I understand',
                onClick: assignDevice,
              },

              button3Props: {
                label: t('common.cancel'),
                onClick: handleAssignModalHide,
                theme: BUTTON_THEMES.white,
              },
            }}
          />
        </AlertModal>

        <AlertModal
          isOpen={unAssignModalOpen}
          onClose={handleUnAssignModalHide}
        >
          <Alert
            topProps={{
              label: t('devices.modal.removeDeviceModal.label'),
              paragraph:
                t('devices.modal.removeDeviceModal.paragraph') ||
                'All settings currently applied to this device would be reset',
              className: styles.modalHeader,
            }}
            bottomProps={{
              button1Props: {
                label: t('devices.modal.removeDeviceModal.remove'),
                onClick: handleReassignButtonClick,
              },
              button2Props: {
                label: t('common.cancel'),
                onClick: handleUnAssignModalHide,
                theme: BUTTON_THEMES.white,
              },
            }}
          />
        </AlertModal>
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default AssignDevice;
