import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Categories,
  DataDailyToday,
  DataUnits,
  OpenTriggerEventType,
  TimeDailyToday,
} from 'Consts/types';

import { ACTIVITY_TYPES, PERIODS } from 'Consts/defintions';

import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';

import CategoryDataUsageGraph from 'UI/Reusable/Data usage graph/Category';

import DataTile from 'UI/Components/Tiles/Data tile';
import Card from 'UI/Elements/Card';
import { ActivityTypes } from 'UI/Components/Graphs/types';

import useGuestDailyDataUsage from 'State/hooks/useGuestDailyDataUsage';
import useGuestDailyOnlineTime from 'State/hooks/useGuestDailyOnlineTime';

import { displayBytes } from 'Utils/mbMath';
import { formatSecondsToHoursAndMinutes } from 'Utils/formatters/date';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { sentenceCase } from 'Utils/formatters/string';
import * as actions from 'State/actions';

import {
  generateCategoryUsageList,
  getHourlyTimeGraph,
  getHourlyDataGraph,
  generateUsageDataCategoryList,
  mostUsedCategories,
  formatHourlyStats,
  getCategoryGraphData,
  getMaxUnitFromGraphData,
} from '../../utils';

import { GuestDataUsageColors, OnlineActivityColors } from 'Consts/defintions';
import dataUsageGraphTooltip from '../../../../UI/Reusable/Data usage graph/Components/Data usage graph tooltip';
import onlineActivityGraphTooltip from '../../../../UI/Reusable/Data usage graph/Components/Online activity graph tooltip';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';

type GraphDataFields = Partial<Record<Categories, number>>;

type GraphColors = Record<string, string>;

type GuestDataUsageUIProps = {
  usage: DataDailyToday | null;
  time: TimeDailyToday | null;
};

type GuestDataUsageSidePanelProps = {
  statistics: [Categories, number][];
  isUsage: boolean;
};

const GuestDataUsageSidePanel: FunctionComponent<
  GuestDataUsageSidePanelProps
> = ({ statistics, isUsage }) => {
  const { t } = useTranslation();
  const total = statistics.reduce((acc, [, number]) => acc + number, 0);

  const colors = isUsage ? GuestDataUsageColors : OnlineActivityColors;

  return (
    <SimpleSidePanel
      title={isUsage ? t('common.dataUsage') : t('common.onlineActivity')}
    >
      <SidePanelContentWrapper>
        {statistics.map(([category, count], index) => {
          const color = colors[index] || colors[2];

          if (count === 0) return null;

          return (
            <Card key={index}>
              <DataTile
                L2TopProps={{
                  label1: t(`categories.${category}`) || sentenceCase(category),
                }}
                RightProps={{
                  smallLabel1: isUsage
                    ? displayBytes(count)
                    : formatSecondsToHoursAndMinutes(count),
                }}
                ProgressBarProps={{
                  color1: color,
                  color2: color,
                  percent: (count / total) * 100,
                }}
              />
            </Card>
          );
        })}
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

const GuestDataUsageUI: FunctionComponent<GuestDataUsageUIProps> = ({
  usage,
  time,
}) => {
  const [activity, setActivity] = useState<ActivityTypes>(ACTIVITY_TYPES.usage);
  const [displaySidePanel, setDisplaySidePanel] = useState(false);
  const { content, setContent } = useSidepanel();
  const { t } = useTranslation();
  const prevActivity = useRef<ActivityTypes>(activity);
  const dispatch = useDispatch<AppDispatch>();

  const isUsage = activity === ACTIVITY_TYPES.usage;
  const { hourlyStats: hourlyStatsUsage, overallStats: overallStatsUsage } =
    usage ?? {};
  const { hourlyStats: hourlyStatsTime, overallStats: overallStatsTime } =
    time ?? {};

  const usageStats = useMemo(
    () => formatHourlyStats(hourlyStatsUsage),
    [hourlyStatsUsage]
  );
  const timeStats = useMemo(
    () => formatHourlyStats(hourlyStatsTime),
    [hourlyStatsTime]
  );

  const sortedCategoryData = useMemo(() => {
    if (isUsage) {
      return generateUsageDataCategoryList(usageStats);
    }

    return generateCategoryUsageList(timeStats);
  }, [isUsage, timeStats, usageStats]);

  const mostUsed = useMemo(
    () => mostUsedCategories(sortedCategoryData),
    [sortedCategoryData]
  );

  const itemsToShow = useMemo(() => {
    return mostUsed.reduce<GraphDataFields>((acc, cur) => {
      return { ...acc, [cur.name]: 0 };
    }, {});
  }, [mostUsed]);

  const colors = useMemo(() => {
    return mostUsed.reduce<GraphColors>((acc, cur, index) => {
      const colors = isUsage ? GuestDataUsageColors : OnlineActivityColors;

      acc[cur.name] = colors[index];

      return acc;
    }, {});
  }, [isUsage, mostUsed]);

  const graphData = useMemo(() => {
    if (isUsage) {
      return getHourlyDataGraph(usageStats, itemsToShow);
    }

    return getHourlyTimeGraph(timeStats, itemsToShow);
  }, [isUsage, itemsToShow, timeStats, usageStats]);

  const averageLabel = useMemo(() => {
    if (isUsage) {
      const value = displayBytes(overallStatsUsage?.hourlyAvgTotalBytes ?? 0);
      return t('graph.avgPerHr', { value });
    }

    const value = Math.round(overallStatsTime?.hourlyAvgOnlineMinutes ?? 0);

    return t('graph.avgMinPerHr', { value });
  }, [
    isUsage,
    overallStatsTime?.hourlyAvgOnlineMinutes,
    overallStatsUsage?.hourlyAvgTotalBytes,
    t,
  ]);

  const maxUnit = useMemo((): DataUnits => {
    if (isUsage) {
      return getMaxUnitFromGraphData(graphData);
    }

    return 'bytes';
  }, [
    isUsage,
    overallStatsTime?.hourlyAvgOnlineMinutes,
    overallStatsUsage?.hourlyAvgTotalBytes,
    t,
  ]);

  const handleDisplaySidePanel = (ev: React.MouseEvent) => {
    setDisplaySidePanel(true);
    handleSidebar(ev);
  };

  const handleSidebar = useCallback(
    (ev?: React.MouseEvent) => {
      ev &&
        dispatch(
          actions.ui.page.setSidepanelOpenTriggerType(
            ev.type as OpenTriggerEventType
          )
        );
      setContent(
        <GuestDataUsageSidePanel
          statistics={sortedCategoryData}
          isUsage={isUsage}
        />
      );
    },
    [sortedCategoryData, isUsage, setContent]
  );

  useEffect(() => {
    if (!content && displaySidePanel) {
      setDisplaySidePanel(false);
    }
    if (displaySidePanel && content && activity !== prevActivity.current) {
      handleSidebar();

      prevActivity.current = activity;
    }
  }, [content, handleSidebar, activity]);

  const displayGraphData = useMemo(() => {
    if (!isUsage) {
      return graphData;
    }

    const { data } = getCategoryGraphData(graphData);

    return data;
  }, [graphData, isUsage]);

  return (
    <CategoryDataUsageGraph
      label={isUsage ? t('common.dataUsage') : t('common.onlineActivity')}
      secondLabel={
        isUsage
          ? displayBytes(
              sortedCategoryData.reduce((acc, [, number]) => acc + number, 0)
            )
          : formatSecondsToHoursAndMinutes(
              sortedCategoryData.reduce((acc, [, number]) => acc + number, 0)
            )
      }
      activityType={activity}
      graphProps={{
        data: displayGraphData,
        colors,
        filters: mostUsed,
        activity,
        customTooltip: isUsage
          ? dataUsageGraphTooltip(maxUnit)
          : onlineActivityGraphTooltip(),
        setActivity,
        averageLabel,
        activityType: activity,
        onCategoryIconClick: handleDisplaySidePanel,
        period: PERIODS.day,
      }}
    />
  );
};

const GuestDataUsage: FunctionComponent = () => {
  const guestDataUsage = useGuestDailyDataUsage();
  const guestTime = useGuestDailyOnlineTime();
  const { t } = useTranslation();

  const isLoading = guestDataUsage.isLoading || guestTime.isLoading;
  const errorMessage = guestDataUsage.errorMessage || guestTime.errorMessage;

  if (isLoading || errorMessage) {
    return (
      <Card
        isLoading={isLoading}
        errorMessage={errorMessage}
        header={{
          L2Props: { label: t('common.dataUsage') },
        }}
      />
    );
  }

  return <GuestDataUsageUI usage={guestDataUsage.data} time={guestTime.data} />;
};

export default GuestDataUsage;
