import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { OpenTriggerEventType, Period } from 'Consts/types';

import PillLarge from 'UI/Elements/Pill large';

import { ActivityTypes } from 'UI/Components/Graphs/types';

import styles from './style.module.css';
import * as actions from 'State/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';

type PeriodFilterProps = {
  period: Period;
  setPeriod: (value: Period) => void;
};

type ActivityFilterProps = {
  activity: ActivityTypes;
  setActivity: (value: ActivityTypes) => void;
};

type FilterProps = Partial<PeriodFilterProps> & Partial<ActivityFilterProps>;

type ListItem<T extends Period | ActivityTypes> = {
  key: T;
  i18key: string;
};

type PeriodProps = ListItem<Period>;

type ActivityProps = ListItem<ActivityTypes>;

const PERIOD_LIST: Partial<Record<Period, PeriodProps>> = {
  day: {
    key: 'day',
    i18key: 'common.today',
  },
  week: {
    key: 'week',
    i18key: 'common.7days',
  },
  month: {
    key: 'month',
    i18key: 'common.30days',
  },
};

const ACTIVITY_LIST: Record<ActivityTypes, ActivityProps> = {
  usage: {
    key: 'usage',
    i18key: 'common.dataUsage',
  },
  online: {
    key: 'online',
    i18key: 'common.onlineActivity',
  },
};

const PeriodFilters: FunctionComponent<PeriodFilterProps> = ({
  period,
  setPeriod,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className={styles.controls} role="tablist">
      {Object.keys(PERIOD_LIST).map((item) => {
        const currentPeriod = PERIOD_LIST[item as Period];
        const isActive = currentPeriod?.key === period;

        const handleOnClick = (ev: React.MouseEvent) => {
          ev.stopPropagation();
          dispatch(
            actions.ui.page.setSidepanelOpenTriggerType(
              ev.type as OpenTriggerEventType
            )
          );
          if (currentPeriod?.key) setPeriod(currentPeriod.key);
        };

        return (
          <PillLarge
            key={item}
            label={t(currentPeriod?.i18key || '')}
            onClick={handleOnClick}
            className={cn({ [styles.active]: isActive })}
            isFilterTab
          />
        );
      })}
    </div>
  );
};

const ActivityFilters: FunctionComponent<ActivityFilterProps> = ({
  activity,
  setActivity,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className={styles.controls} role="tablist">
      {Object.keys(ACTIVITY_LIST).map((item) => {
        const currentPeriod = ACTIVITY_LIST[item as ActivityTypes];
        const isActive = currentPeriod.key === activity;

        const handleOnClick = (ev: React.MouseEvent) => {
          ev.stopPropagation();

          dispatch(
            actions.ui.page.setSidepanelOpenTriggerType(
              ev.type as OpenTriggerEventType
            )
          );
          if (currentPeriod?.key) setActivity(currentPeriod.key);
        };

        return (
          <PillLarge
            key={item}
            label={t(currentPeriod.i18key)}
            onClick={handleOnClick}
            className={cn({ [styles.active]: isActive })}
            isFilterTab
          />
        );
      })}
    </div>
  );
};

const Filters: FunctionComponent<FilterProps> = ({
  period,
  setPeriod,
  activity,
  setActivity,
}) => {
  if (period && setPeriod) {
    return <PeriodFilters period={period} setPeriod={setPeriod} />;
  }

  if (activity && setActivity) {
    return <ActivityFilters activity={activity} setActivity={setActivity} />;
  }

  return null;
};

export default Filters;
