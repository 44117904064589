import cn from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { Body2, Body2Medium, FinePrint1 } from 'UI/Elements/Typography';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type TabProps = {
  label: string;
  subLabel?: string;
  iconName?: IconName;
  key?: string;
};

type SegmentedControlsProps = {
  tabs: TabProps[];
  className?: string;
  onClick?: (index: number) => void;
  initialActiveIndex?: number;
};

const SegmentedControls: FunctionComponent<SegmentedControlsProps> = ({
  tabs,
  onClick,
  className,
  initialActiveIndex = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  return (
    <div role="tablist" className={cn(styles.tabs, className)}>
      {tabs.map(({ iconName, subLabel, label }, index) => {
        const isActive = index === activeIndex;
        const handleOnClick = () => {
          setActiveIndex(index);
          onClick?.(index);
        };

        if (iconName && subLabel) {
          return null;
        }

        const classNames = cn(styles.tab, {
          [styles.activeTab]: isActive,
          [styles.withIcon]: !!iconName,
          [styles.withSubLabel]: !!subLabel,
        });

        return (
          <div
            role="tab"
            aria-selected={isActive}
            tabIndex={0}
            key={index}
            className={classNames}
            onClick={handleOnClick}
            onKeyDown={(e) => onEnterKeydown(e, handleOnClick)}
          >
            {iconName && (
              <Icon
                className={cn(styles.icon, { [styles.active]: isActive })}
                name={iconName}
              />
            )}

            <div className={styles.labelWrapper}>
              {subLabel ? (
                <Body2 className={cn({ [styles.activeLabel]: isActive })}>
                  {label}
                </Body2>
              ) : (
                <Body2Medium className={cn({ [styles.activeLabel]: isActive })}>
                  {label}
                </Body2Medium>
              )}

              {subLabel && <FinePrint1>{subLabel}</FinePrint1>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SegmentedControls;
