import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import CircularProgressBar, {
  CircularProgressBarProps,
} from 'UI/Elements/Circular Progress Bar';
import Icon, { Props as IconProps } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import ProgressBar, {
  Props as ProgressBarProps,
} from 'UI/Elements/Progress bar';
import PillSmall from 'UI/Elements/Pill small';
import { Body1Medium, Body3 } from 'UI/Elements/Typography';
import MediumDeviceIcon, {
  Props as DeviceIconProps,
} from 'UI/Elements/Icon/device';

import DeviceButton, { ButtonProps } from './Button';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type L1Props = {
  className?: string;
  iconProps?: IconProps;
  mediumDeviceIconProps?: DeviceIconProps;
};

const L1: FunctionComponent<L1Props> = ({
  className,
  iconProps,
  mediumDeviceIconProps,
}) => {
  if (!iconProps && !mediumDeviceIconProps) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.l1,
        {
          [styles.justMediumDevice]:
            mediumDeviceIconProps && !iconProps?.name && !className,
        },
        className
      )}
    >
      {iconProps && <Icon {...iconProps} />}

      {mediumDeviceIconProps && <MediumDeviceIcon {...mediumDeviceIconProps} />}
    </div>
  );
};

export type Paragraph = {
  label: string;
  prefix?: React.ReactNode;
  color?: string;
  key?: string;
};

export type L2Props = {
  className?: string;
  label?: string;
  labelColor?: string;
  secondLabel?: string;
  paragraphList?: Paragraph[];
  smallPillLabel?: string;
  progressBarProps?: ProgressBarProps;
  button1Props?: ButtonProps;
  button2Props?: ButtonProps;
};

const L2: FunctionComponent<L2Props> = ({
  className,
  label,
  labelColor,
  secondLabel,
  smallPillLabel,
  paragraphList = [],
  button1Props,
  button2Props,
  progressBarProps,
}) => {
  return (
    <div className={cn(styles.l2, className)}>
      {(label || smallPillLabel) && (
        <div className={cn(styles.l2labelRow)}>
          {label && <Body1Medium className={labelColor}>{label}</Body1Medium>}
          {secondLabel && (
            <Body1Medium className={colorStyles.still400}>
              {secondLabel}
            </Body1Medium>
          )}
          {smallPillLabel && <PillSmall>{smallPillLabel}</PillSmall>}
        </div>
      )}

      {progressBarProps && (
        <ProgressBar
          {...progressBarProps}
          className={cn(styles.progressBar, progressBarProps?.className)}
        />
      )}

      {paragraphList?.map(
        (
          { label: paragraphLabel, prefix, color = colorStyles.still400 },
          i
        ) => (
          <div className={styles.paragraphsWrapper} key={i || paragraphLabel}>
            {prefix}
            <Body3 className={color}>{paragraphLabel}</Body3>
          </div>
        )
      )}

      {(button1Props || button2Props) && (
        <div className={styles.l2buttonRow}>
          {button1Props && <DeviceButton {...button1Props} />}
          {button2Props && <DeviceButton {...button2Props} />}
        </div>
      )}
    </div>
  );
};

export type RProps = {
  className?: string;
  circularProgressBarProps?: CircularProgressBarProps;
  iconProps?: IconProps;
};

const R: FunctionComponent<RProps> = ({
  className,
  circularProgressBarProps,
  iconProps,
}) => {
  if (!iconProps && !circularProgressBarProps) {
    return null;
  }

  return (
    <div className={cn(styles.r, className)}>
      {iconProps && (
        <Icon
          {...iconProps}
          className={iconProps.className}
          shape={
            iconProps.onClick || iconProps.tooltipLabel
              ? IconShape.square
              : undefined
          }
        />
      )}
      {circularProgressBarProps && (
        <CircularProgressBar {...circularProgressBarProps} />
      )}
    </div>
  );
};

type DeviceListItemProps = {
  className?: string;
  L1Props?: L1Props;
  L2Props?: L2Props;
  RProps?: RProps;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
};

const DeviceListItem: FunctionComponent<DeviceListItemProps> = ({
  className,
  L1Props = {},
  L2Props = {},
  RProps = {},
  onClick,
  disabled,
}) => {
  return (
    <div
      className={cn(
        styles.deviceListItem,
        {
          [styles.disabled]: disabled,
        },
        className
      )}
      onClick={onClick}
      data-testid="device-list-item"
      role="link"
      tabIndex={0}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
    >
      <L1 {...L1Props} />
      <L2 {...L2Props} />
      <R {...RProps} />
    </div>
  );
};

export default DeviceListItem;
