import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';
import { BandwidthConsumption, ConnectionStateTypes } from 'Consts/types';

import { V2Card } from 'UI/Elements/Card';
import Divider from 'UI/Elements/Divider';

import useCheckSpeed from 'State/hooks/useCheckSpeed';
import useIspSpeedTest from 'State/hooks/useIspSpeedTest';
import * as selectors from 'State/selectors';

import {
  formatDistanceFromNow,
  formatRelativeToNow,
} from 'Utils/formatters/date';

import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import DeviceList, { DeviceListProps } from './Components/Devices';
import Header, { HeaderProps } from './Components/Header';
import InternetSpeed, { InternetSpeedProps } from './Components/Internet speed';

type WlanUIProps = HeaderProps &
  InternetSpeedProps &
  DeviceListProps & {
    isLoading?: boolean;
    errorMessage?: string;
    networkStatus: string;
    isSpeedTestCapable: boolean;
    hideSpeedTestUI?: boolean;
    onViewAllClick: React.MouseEventHandler;
  };

export const WlanUI: FunctionComponent<WlanUIProps> = ({
  isLoading,
  errorMessage,
  isp,
  lastChecked,
  speedTestState,
  download,
  upload,
  totalConsumption,
  devices,
  isSpeedTestCapable,
  networkStatus,
  hideSpeedTestUI,
  onCheckSpeed,
  onViewAllClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <V2Card
        isLoading={isLoading}
        errorMessage={errorMessage}
        footer={{
          label: t('common.viewMore'),
          ariaLabel: t('wlan.viewMoreAriaLabel'),
          onClick: onViewAllClick,
        }}
        noBottomPadding
      >
        <Header
          isp={
            isLoading ? t('wlan.internet') : isp ? isp : t('wlan.unknownISP')
          }
          onCheckSpeed={
            isSpeedTestCapable && !hideSpeedTestUI ? onCheckSpeed : null
          }
          networkStatus={networkStatus}
        />
        <Divider />

        {!hideSpeedTestUI && (
          <InternetSpeed
            speedTestState={speedTestState}
            download={download}
            upload={upload}
            lastChecked={lastChecked}
          />
        )}
        <Divider />

        <DeviceList
          viewMoreView={false}
          totalConsumption={totalConsumption}
          devices={devices}
          devicesToShow={3}
        />
      </V2Card>
    </>
  );
};

const WlanCard: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const speedTest = useIspSpeedTest();

  const activeLocation = useSelector(selectors.locations.activeLocation);
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const { onCheckSpeed, speedTestState } = useCheckSpeed();

  const isSpeedTestCapable = !!activeLocation.data?.capabilities?.ispSpeedTest;

  const networkStatus = (activeLocation.data?.summary?.gatewayConnectionState ||
    'disconnected') as ConnectionStateTypes;

  const handleViewAllClick = useCallback(() => {
    if (!speedTest.data) {
      return;
    }

    navigate(ROUTES.home.wlan);
  }, [navigate, speedTest.data]);

  const { dashboard, day } = speedTest.data ?? {};

  const timestamp = dashboard?.mostRecent.timestamp ?? 0;

  const hideISPName =
    customerSupportConfigurations?.customerFeatureEnablement.hideIspNameEnabled;

  const lastChecked = timestamp
    ? Date.now() - timestamp < 86400000
      ? formatDistanceFromNow(new Date(timestamp)) + ' ' + t('wlan.ago')
      : formatRelativeToNow(new Date(timestamp))
    : '';

  const defaultConsumption: BandwidthConsumption = {
    download: 0,
    upload: 0,
    downloadUnits: 'mb',
    uploadUnits: 'mb',
  };

  return (
    <>
      <WlanUI
        isLoading={speedTest.isLoading && !speedTest.data}
        errorMessage={speedTest.errorMessage}
        isp={
          hideISPName
            ? t('wlan.genericISP')
            : dashboard?.isp || t('wlan.unknownISP')
        }
        lastChecked={lastChecked}
        speedTestState={speedTestState}
        download={dashboard?.mostRecent.download ?? 0}
        upload={dashboard?.mostRecent.upload ?? 0}
        totalConsumption={day?.devices?.totalConsumption ?? defaultConsumption}
        devices={day?.devices?.mostActive ?? []}
        networkStatus={networkStatus}
        isSpeedTestCapable={isSpeedTestCapable}
        hideSpeedTestUI={
          customerSupportConfigurations?.customerFeatureEnablement
            .hideSpeedTestUiEnabled
        }
        onCheckSpeed={onCheckSpeed}
        onViewAllClick={handleViewAllClick}
      />
    </>
  );
};

export default WlanCard;
