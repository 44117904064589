import { CONNECTION_STATE, mediumProfilePhoto } from 'Consts/defintions';
import { Person } from 'Consts/types';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePersonDailyOnlineTime from 'State/hooks/usePersonDailyOnlineTime';
import usePersonMonthlyOnlineTime from 'State/hooks/usePersonMonthlyOnlineTime';
import colorStyles from 'Styles/colors.module.css';
import { V2Card } from 'UI/Elements/Card';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import V2CardHeader from 'UI/Molecules/CardHeader';
import { formatDistanceFromNow, formatHour } from 'Utils/formatters/date';
import OnlineTimeGraph from '../Graph';
import styles from './style.module.css';

const MAX_NICKNAME_LENGTH = 16;

const truncateNickname = (nickname: string): string => {
  if (nickname.length <= MAX_NICKNAME_LENGTH) return nickname;
  return nickname.slice(0, MAX_NICKNAME_LENGTH) + '...';
};

type EmployeeCardProps = {
  person: Person;
  warnAboutManualCreation?: boolean;
  onClick?: React.MouseEventHandler;
};

const EmployeeCard: FunctionComponent<EmployeeCardProps> = ({
  person,
  warnAboutManualCreation = false,
  onClick,
}) => {
  const { t } = useTranslation();

  const daily = usePersonDailyOnlineTime(person.id, person.primaryDevice);
  const monthly = usePersonMonthlyOnlineTime(person.id, person.primaryDevice);

  const personDailyData = daily.data?.[person.id];
  const personMonthlyData = monthly.data?.[person.id];

  const isLoading = daily.isLoading || monthly.isLoading;
  const errorMessage = daily.errorMessage || monthly.errorMessage;

  const statusHome =
    person.connectionState === CONNECTION_STATE.connected &&
    !!person.connectionStateChangeAt;
  const statusAway =
    person.connectionState === CONNECTION_STATE.disconnected &&
    !!person.connectionStateChangeAt;
  const statusNoDevice = !person.primaryDevice;

  const statusText = statusNoDevice
    ? t('employeeZone.assignPrimaryDevice')
    : statusHome
    ? t('homepage.atWorkSince', {
        time: formatHour(new Date(person.connectionStateChangeAt || '')),
      })
    : statusAway
    ? t('employeeZone.lastSeen', {
        date: formatDistanceFromNow(
          new Date(person.connectionStateChangeAt || '')
        ),
      })
    : '';

  const truncatedNickname = useMemo(
    () => truncateNickname(person.nickname),
    [person.nickname]
  );

  return (
    <V2Card
      isLoading={isLoading}
      errorMessage={errorMessage}
      className={styles.employeeCard}
      header={
        <V2CardHeader
          left={
            <Icon
              name={
                IconNames[
                  mediumProfilePhoto[person.imageId] || 'Profile40Generic04'
                ]
              }
            />
          }
          title={truncatedNickname}
          subtitle={statusText}
          subtitleColor={!statusNoDevice ? 'still400' : 'hot500'}
          right={
            warnAboutManualCreation &&
            person?.serviceLinking?.providerId !== 'warden' && (
              <Icon
                className={colorStyles.sore500}
                name={IconNames['PersonExclamation']}
                shape={IconShape.square}
                onClick={() => console.log('FIXME: JIRA-6159')}
                tooltipLabel={t('employeeZone.personExclamation')}
              />
            )
          }
        />
      }
      collapsible={!statusNoDevice}
      defaultsToCollapsed={statusNoDevice || !statusHome}
      onClick={onClick}
    >
      {!statusNoDevice && (
        <OnlineTimeGraph
          placeInCard
          daily={personDailyData}
          monthly={personMonthlyData}
        />
      )}
    </V2Card>
  );
};

export default EmployeeCard;
