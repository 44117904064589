import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { DeviceGroup, ZoneDevice } from 'Consts/types';

import Background from 'UI/Layout/Background';
import Page from 'UI/Layout/Page';
import { FullscreenPageHeader } from 'UI/Layout/Page header';

import Filters from 'UI/Reusable/Filters/Filters';

import Alert from 'UI/Components/Alert';
import Menu, { MenuItemProps } from 'UI/Components/Menu';

import { BUTTON_THEMES } from 'UI/Elements/Button';
import AlertModal from 'UI/Elements/Modals/Alerts';

import useDeviceFilters from 'State/hooks/useDeviceFilters';
import useDeviceGroup from 'State/hooks/useDeviceGroup';
import useDevices from 'State/hooks/useDevices';

import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';

import useIsMobile from 'Utils/hooks/useIsMobile';
import useLayoutColumns from 'Utils/hooks/useLayout';

import {
  useChangeGroup,
  useMenuItems,
} from '../Components/Devices/Device menu items';
import DevicesCard from '../Components/Devices/Devices card';
import { View, Zone } from '../Components/Devices/types';

import { DeviceFilters, groupDevices, sortDevices } from './utils';

import useNetworkAccess from 'State/hooks/useNetworkAccess';
import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import cn from 'classnames';
import styles from './style.module.css';

import { ROUTES } from 'Consts/routes';
import * as globalActions from '../../../State/actions';
import Grid from 'UI/Layout/Grid';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

type DevicesViewAllProps = {
  zone: Zone;
  view: View | 'assigned';
};

const DevicesViewAll: FunctionComponent<DevicesViewAllProps> = ({
  zone,
  view,
}) => {
  const { t } = useTranslation();
  const [columns, ref] = useLayoutColumns();
  const { groupId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useIsMobile();
  const { id } = useParams();
  const closeAlertTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const {
    isMenuOpen,
    handleClick,
    handleOnClose,
    parent,
    sortingType,
    groupingType,
    filterItems,
    allFilterItems,
    menuOpenTrigger,
  } = useDeviceFilters();

  const {
    allDevices: {
      isLoading: isLoadingDevices,
      errorMessage: errorMessageDevices,
      data: allDevices,
    },
    defaultDevices,
    employeeDevices,
  } = useDevices();

  const {
    data: deviceGroups,
    isLoading: isLoadingGroups,
    errorMessage: errorMessageGroups,
  } = useDeviceGroup();

  const deviceGroup = useMemo(() => {
    return deviceGroups?.default?.find((group) => group.groupId === groupId);
  }, [deviceGroups?.default, groupId]);

  const isLoading = isLoadingDevices || isLoadingGroups;
  const errorMessage = errorMessageDevices || errorMessageGroups;
  const navigate = useNavigate();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState<'new' | 'rename' | null>(null);
  const [groupName, setGroupName] = useState('');
  const [hasError, setHasError] = useState(false);

  const networkAccessData = useNetworkAccess();
  const secureNetworkAccessMode = networkAccessData?.data?.default?.purgatory;
  const employeeNetworkAccessMode =
    networkAccessData?.data?.employee?.purgatory;

  const handleOpenAlert = useCallback((type: 'new' | 'rename') => {
    setOpenAlert(type);
    setIsAlertOpen(true);
  }, []);

  const commonFilter = { filterKey: 'Filter', label: t('common.filter') };
  const parentRef = useRef<Element | null>(null);
  const {
    MENU_ITEMS,
    actions: { saveGroup },
  } = useMenuItems({
    onNewGroup: () => handleOpenAlert('new'),
    onRenameGroup: () => handleOpenAlert('rename'),
    onDeleteGroup: () => navigate(-1),
  });

  const closeAlert = useCallback(() => {
    if (closeAlertTimeoutId.current) {
      return;
    }

    setIsAlertOpen(false);

    closeAlertTimeoutId.current = setTimeout(() => {
      if (
        !setGroupName ||
        !setHasError ||
        !setOpenAlert ||
        !closeAlertTimeoutId
      ) {
        return;
      }

      setGroupName('');
      setHasError(false);

      setOpenAlert(null);

      closeAlertTimeoutId.current = null;
    }, 200);
  }, []);

  const assignedDevices = useMemo(() => {
    return allDevices?.filter((device) => device.personId === id) || [];
  }, [allDevices]);

  useEffect(() => {
    if (!isLoadingGroups) {
      if (deviceGroup) {
        dispatch(actions.ui.devicesAndGroups.selectGroup(deviceGroup));
      } else {
        // FES-6038
        if (zone === 'secure' && view === 'approved' && groupId) {
          dispatch(
            globalActions.ui.errorAlert.set({
              errorState: {
                message: `This device group cannot be found`,
                redirectRoute: ROUTES.home.index,
              },
            })
          );
        } else if (
          !isLoadingDevices &&
          !assignedDevices.length &&
          zone === 'employee' &&
          view === 'assigned'
        ) {
          dispatch(
            globalActions.ui.errorAlert.set({
              errorState: {
                message: `This device group cannot be found`,
                redirectRoute: ROUTES.home.index,
              },
            })
          );
        }
      }
    }

    return () => {
      dispatch(actions.ui.devicesAndGroups.selectGroup(null));
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: '',
          },
        })
      );
    };
  }, [
    dispatch,
    deviceGroup,
    isLoadingGroups,
    assignedDevices,
    isLoadingDevices,
  ]);

  const onGroupNameChange = (value: string) => {
    setHasError(Boolean(!value));
    setGroupName(value);
  };

  const handleSaveGroup = useCallback(() => {
    if (!groupName) {
      setHasError(true);
    } else {
      saveGroup(groupName, openAlert === 'new');
      closeAlert();
    }
  }, [groupName, saveGroup, openAlert, closeAlert]);

  useEffect(() => {
    setGroupName(deviceGroup ? deviceGroup.name : '');
  }, [deviceGroup, openAlert]);

  const selectedGroup = useSelector(
    selectors.ui.devicesAndGroups.selectedGroup
  );

  const headerMenuOpen = useSelector(
    selectors.ui.devicesAndGroups.headerMenuOpen
  );
  const changeMenuOpen = useSelector(
    selectors.ui.devicesAndGroups.changeMenuOpen
  );
  const menuTriggerType = useSelector(
    selectors.ui.devicesAndGroups.devicesAndGroupsMenuOpenTrigger
  );

  const ungroupedDevices = defaultDevices?.approved.filter(
    ({ mac }: ZoneDevice) => {
      return deviceGroups?.default
        ?.filter((group: DeviceGroup) => !group.standalone)
        .every((group: DeviceGroup) => {
          return !group.devices?.includes(mac);
        });
    }
  );

  const getViewAllDeviceTitleAndinfoTooltip = useMemo(() => {
    let title = '';
    let infoTooltipLabel = '';

    switch (view) {
      case 'unapproved':
        title = t('devices.unapprovedDevices');
        infoTooltipLabel = t('devices.unapprovedTooltipSecure');
        break;
      case 'assigned':
        title = t('homepage.assignedDevices');
        break;
      case 'blocked':
        title = t('devices.blockedDevices');
        infoTooltipLabel = t('devices.blockedTooltip');
        break;
      case 'quarantined':
        title = t('devices.quarantinedDevices');
        infoTooltipLabel = t('devices.quarantinedTooltip');
        break;
      case 'approved':
        if (zone === 'secure') {
          if (deviceGroup) {
            title = secureNetworkAccessMode
              ? `${t('devices.approvedDevices')} - ${deviceGroup.name}`
              : deviceGroup.name;
          } else {
            title = secureNetworkAccessMode
              ? `${t('devices.approvedDevices')} - ${t('common.defaultGroup')}`
              : t('devices.devices');
          }
          infoTooltipLabel = t('devices.approvedTooltipSecure');
        }
        if (zone === 'employee') {
          title = employeeNetworkAccessMode
            ? t('devices.approvedDevices')
            : t('devices.devices');
          infoTooltipLabel = t('devices.approvedTooltipEmployee');
        }
        break;
    }

    return { title, infoTooltipLabel };
  }, [
    view,
    zone,
    deviceGroup,
    networkAccessData,
    secureNetworkAccessMode,
    employeeNetworkAccessMode,
    t,
  ]);

  const { title, devices, headerMenuItems, menuItems, infoTooltipLabel } =
    useMemo(() => {
      const { title: _title, infoTooltipLabel: _infoTooltipLabel } =
        getViewAllDeviceTitleAndinfoTooltip;

      let _devices: ZoneDevice[] = [];
      let _headerMenuItems: MenuItemProps[] = [];
      let _menuItems: MenuItemProps[] = [];

      switch (view) {
        case 'unapproved':
          if (zone === 'secure') {
            _devices = defaultDevices?.auto || [];
          } else if (zone === 'employee') {
            _devices = employeeDevices?.auto || [];
          }

          _headerMenuItems = [
            MENU_ITEMS.approveDevices,
            MENU_ITEMS.blockDevices,
          ];

          _menuItems = [
            ...(zone === 'employee' ? [MENU_ITEMS.assignDevice] : []),
            MENU_ITEMS.approveDevice,
            MENU_ITEMS.deviceSettings,
            MENU_ITEMS.macAndIPAddress,
            MENU_ITEMS.blockDevice,
          ];

          break;

        case 'approved':
          if (zone === 'secure') {
            if (!groupId) {
              _devices = ungroupedDevices!;

              _headerMenuItems = [
                ...(ungroupedDevices?.length && deviceGroups?.default.length
                  ? [MENU_ITEMS.changeGroup]
                  : []),
                MENU_ITEMS.newGroup,
                MENU_ITEMS.blockDevices,
              ];
            } else if (deviceGroup) {
              _devices =
                defaultDevices?.approved.filter(({ mac }) =>
                  deviceGroup.devices?.includes(mac)
                ) || [];

              _headerMenuItems = [
                MENU_ITEMS.renameGroup,
                MENU_ITEMS.groupShareAccess,
                MENU_ITEMS.blockDevices,
                MENU_ITEMS.deleteGroup,
              ];
            }

            _menuItems = [
              MENU_ITEMS.changeGroup,
              MENU_ITEMS.newGroup,
              MENU_ITEMS.deviceShareAccess,
              MENU_ITEMS.deviceSettings,
              MENU_ITEMS.macAndIPAddress,
              MENU_ITEMS.blockDevice,
            ];
          } else if (zone === 'employee') {
            _devices = employeeDevices?.approved || [];

            _headerMenuItems = [MENU_ITEMS.blockDevices];

            _menuItems = [
              // Removed until implemented // MENU_ITEMS.shareAccess,
              MENU_ITEMS.assignDevice,
              MENU_ITEMS.deviceSettings,
              MENU_ITEMS.macAndIPAddress,
              MENU_ITEMS.blockDevice,
            ];
          }

          break;

        case 'assigned':
          _devices = assignedDevices || [];
          _menuItems = [
            MENU_ITEMS.deviceSettings,
            MENU_ITEMS.macAndIPAddress,
            MENU_ITEMS.deviceShareAccess,
            MENU_ITEMS.unAssignDevice,
          ];
          break;

        case 'blocked':
          if (zone === 'secure') {
            _devices = defaultDevices?.blocked || [];
          } else if (zone === 'employee') {
            _devices = employeeDevices?.blocked || [];
          }

          _headerMenuItems = [MENU_ITEMS.approveDevices];

          _menuItems = [
            ...(zone === 'employee' ? [MENU_ITEMS.assignDevice] : []),
            MENU_ITEMS.approveDevice,
            MENU_ITEMS.deviceSettings,
            MENU_ITEMS.deviceShareAccess,
            MENU_ITEMS.macAndIPAddress,
          ];
          break;

        case 'quarantined':
          if (zone === 'secure') {
            _devices = defaultDevices?.quarantined || [];
          } else if (zone === 'employee') {
            _devices = employeeDevices?.quarantined || [];
          }

          _headerMenuItems = [
            MENU_ITEMS.unquarantine1hr,
            MENU_ITEMS.unquarantinePermanently,
          ];

          _menuItems = [
            ...(zone === 'employee' ? [MENU_ITEMS.assignDevice] : []),
            MENU_ITEMS.unquarantine1hr,
            MENU_ITEMS.unquarantinePermanently,
            MENU_ITEMS.deviceSettings,
            MENU_ITEMS.macAndIPAddress,
          ];

          break;

        default:
          break;
      }

      return {
        title: _title,
        devices: _devices,
        headerMenuItems: _headerMenuItems,
        menuItems: _menuItems,
        infoTooltipLabel: _infoTooltipLabel,
      };
    }, [
      MENU_ITEMS,
      defaultDevices,
      deviceGroups?.default.length,
      employeeDevices,
      deviceGroup,
      groupId,
      ungroupedDevices,
      view,
      zone,
      t,
    ]);

  const changeMenuItems = useChangeGroup();
  useEffect(() => {
    if (!changeMenuOpen && !headerMenuOpen) {
      parentRef.current = null;
    }
  }, [changeMenuOpen, headerMenuOpen]);

  const items = useMemo(() => {
    return changeMenuOpen
      ? changeMenuItems
      : headerMenuOpen
      ? headerMenuItems
      : [];
  }, [changeMenuItems, changeMenuOpen, headerMenuItems, headerMenuOpen]);

  const groupedAndSortedDevices: [string, ZoneDevice[]][] = useMemo(() => {
    if (!devices) {
      return [];
    }

    const _devices = [...devices];

    const _groupedDevices = groupDevices(
      _devices as DeviceFilters,
      groupingType
    );

    return _groupedDevices.map(([groupLabel, devices]) => {
      return [groupLabel, sortDevices(devices, sortingType, deviceGroup)];
    });
  }, [devices, deviceGroup, groupingType, sortingType]) as [
    string,
    ZoneDevice[]
  ][];

  const allItems = [
    ...allFilterItems.Sort,
    { isDivider: true },
    ...allFilterItems.Group,
  ];
  const handleHeaderMenuOpen = useCallback(
    (ev: React.MouseEvent) => {
      dispatch(actions.ui.devicesAndGroups.selectDevices(devices));

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(actions.ui.devicesAndGroups.setHeaderMenuOpen(true));
      dispatch(
        actions.ui.devicesAndGroups.setDevicesAndGroupsMenuOpenTrigger(
          ev.type as MenuOpenTriggerEventType
        )
      );
      parentRef.current = ev.currentTarget;
    },
    [devices, dispatch]
  );

  const handleHeaderMenuOnClose = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    parentRef.current = null;
  }, [dispatch]);

  return (
    <Page>
      <AlertModal isOpen={isAlertOpen} onClose={closeAlert}>
        <Alert
          topProps={{
            label:
              openAlert === 'rename'
                ? t('devices.renameTheGroup')
                : t('devices.nameTheGroup'),
          }}
          middleProps={{
            listInputProps: {
              value: groupName,
              onChange: onGroupNameChange,
              placeholder: t('devices.groupAdmin.groupNameCreatePlaceholder'),
              paragraph: hasError
                ? (t('devices.nameIsRequired') as string)
                : '',
              hasError: hasError,
              onSubmit: handleSaveGroup,
            },
          }}
          bottomProps={{
            button1Props: {
              label: t('common.save'),
              onClick: handleSaveGroup,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: closeAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>

      <Background ref={ref}>
        <FullscreenPageHeader
          label={title}
          backRoute={-1}
          RProps={{
            icon1Props: {
              name: IconNames.OverflowVertical,
              className: cn(styles.OverflowVertical),
              onClick: handleHeaderMenuOpen,
              tooltipLabel: '',
              ariaLabel: t('common.moreActions'),
              shape: IconShape.square,
            },
          }}
        >
          <Filters
            parent={parent}
            isMenuOpen={isMenuOpen}
            menuItems={isMobile ? allItems : filterItems}
            handleClick={handleClick}
            handleOnClose={handleOnClose}
            filters={isMobile ? [commonFilter] : undefined}
            menuOpenTrigger={menuOpenTrigger}
          />
          <Menu
            isOpen={headerMenuOpen || changeMenuOpen}
            parent={parentRef.current}
            items={items}
            onClose={handleHeaderMenuOnClose}
            openTriggerEventType={menuTriggerType}
          />
        </FullscreenPageHeader>

        <Grid columnCount={columns} topMargin={16}>
          {!groupedAndSortedDevices.length ? (
            <DevicesCard
              group={selectedGroup ? selectedGroup : undefined}
              className={styles.deviceCard}
              isLoading={isLoading}
              errorMessage={errorMessage}
              columnSpan={columns}
              title={t('common.allDevices')}
              devices={[]}
              headerMenuItems={[]}
            />
          ) : (
            groupedAndSortedDevices.map((displayGroup, i) => {
              const [title, devices] = displayGroup;
              return (
                <React.Fragment key={i}>
                  <DevicesCard
                    group={selectedGroup ? selectedGroup : undefined}
                    className={styles.deviceCard}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    columnSpan={columns}
                    title={title}
                    devices={devices ?? []}
                    headerMenuItems={[]}
                    menuItems={menuItems}
                    infoTooltipLabel={infoTooltipLabel}
                  />
                </React.Fragment>
              );
            })
          )}
        </Grid>
      </Background>
    </Page>
  );
};

export default DevicesViewAll;
