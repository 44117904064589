import React, { FunctionComponent } from 'react';
import Icon, { IconNames, IconName } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type CarouselItem = {
  id: string;
  name: IconName;
};

export type CarouselProps = {
  carouselItems: Array<Partial<CarouselItem>>;
  onPrev: React.MouseEventHandler<HTMLDivElement>;
  onNext: React.MouseEventHandler<HTMLDivElement>;
  selectedIndex: number;
};

const Carousel: FunctionComponent<CarouselProps> = ({
  carouselItems,
  onPrev,
  onNext,
  selectedIndex,
}) => {
  const { t } = useTranslation();
  const iconname = carouselItems[selectedIndex].name;

  return (
    <div className={cn(styles.carouselContainer)}>
      <div
        tabIndex={0}
        role="button"
        onClick={onPrev}
        onKeyDown={(e) => onEnterKeydown(e, onPrev)}
        aria-label={t('common.previous')}
      >
        <Icon
          name={IconNames.ChevronLeftCompact}
          className={cn(styles.chevronLeft)}
          tooltipLabel={''}
          shape={IconShape.square}
        />
      </div>
      {iconname && <Icon className={cn(styles.carouselIcon)} name={iconname} />}
      <div
        tabIndex={0}
        role="button"
        onClick={onNext}
        onKeyDown={(e) => onEnterKeydown(e, onNext)}
        aria-label={t('common.next')}
      >
        <Icon
          name={IconNames.ChevronRightCompact}
          className={cn(styles.chevronRight)}
          tooltipLabel={''}
          shape={IconShape.square}
        />
      </div>
    </div>
  );
};

export default Carousel;
