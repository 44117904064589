import Background from 'UI/Layout/Background';
import Page from 'UI/Layout/Page';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './style.module.css';
import { isPureDevDomain } from 'subDomainConfiguration';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import colorStyles from 'Styles/colors.module.css';
import { useTranslation } from 'react-i18next';
import { Body1 } from 'UI/Elements/Typography';
import cn from 'classnames';
import Loader from 'UI/Elements/Loader';
import './iframe.css';

const NEXT_STG_KEY = process.env.REACT_APP_NEXT_STG_KEY;
const NEXT_PROD_KEY = process.env.REACT_APP_NEXT_PROD_KEY;
const AFFILIATE_NAME = 'PLUME';

const stgUrl = 'uat.nextinsurance.com';
const prodUrl = 'app.nextinsurance.com';

const NextIframe: FunctionComponent = () => {
  const { t } = useTranslation();
  const [signature, setSignature] = useState('');
  const [timestamp, setTimestamp] = useState(0);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [attemptingToLoadPage, setAttemptingToLoadPage] = useState(true);
  const baseUrl = isPureDevDomain() ? stgUrl : prodUrl;
  const referenceKey = isPureDevDomain() ? NEXT_STG_KEY : NEXT_PROD_KEY;

  const loadScript = () => {
    const script = document.createElement('script');
    script.src = `https://${baseUrl}/ni-sdk.bundle.js`;
    script.async = true;
    const div = document.getElementsByClassName('next-insurance-button')[0];
    div.appendChild(script);
  };

  useEffect(() => {
    if (!referenceKey) return;
    const currentTimestamp = Date.now();
    setTimestamp(currentTimestamp);
    const hash = sha256(referenceKey + currentTimestamp).toString(
      CryptoJS.enc.Hex
    );
    setSignature(hash);
    loadScript();
  }, []);

  useEffect(() => {
    const iframeLoaded = () => {
      const iframe = document.getElementById(
        'next-sdk'
      ) as HTMLIFrameElement | null;
      if (iframe) {
        setAttemptingToLoadPage(false);
        return true;
      }
      return false;
    };

    const intervalDelay = 500;
    const maxAttempts = 10;
    let attempts = 0;
    const intervalId = setInterval(() => {
      if (iframeLoaded()) {
        clearInterval(intervalId);
      } else if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        setAttemptingToLoadPage(false);
        setPageNotFound(true);
      }
      attempts++;
    }, intervalDelay);
  }, []);

  return (
    <Page>
      <Background
        contentClassName={styles.backgroundContent}
        className={styles.background}
      >
        {attemptingToLoadPage && (
          <div className={styles.loadingOrErrorBackground}>
            <Loader />
          </div>
        )}
        <div
          className="next-insurance-button"
          data-signature={signature}
          data-timestamp={timestamp}
          data-affiliate-name={AFFILIATE_NAME}
          data-embed="true"
          data-env={isPureDevDomain() ? 'sandbox' : undefined}
        />
        {pageNotFound && (
          <div className={styles.loadingOrErrorBackground}>
            <Body1 className={cn(styles.errorMessage, colorStyles.still400)}>
              {t('insurance.pageNotFoundError')}
            </Body1>
          </div>
        )}
      </Background>
    </Page>
  );
};

export default NextIframe;
