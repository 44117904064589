import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'Consts/routes';
import { ZoneDevice } from 'Consts/types';

import {
  DeviceFilters,
  groupDevices,
  sortDevices,
} from 'Pages/Zones/Device management view all/utils';

import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import { FullscreenPageHeader } from 'UI/Layout/Page header';

import Filters from 'UI/Reusable/Filters/Filters';
import { MenuItemProps } from 'UI/Components/Menu';

import useDeviceFilters from 'State/hooks/useDeviceFilters';

import * as selectors from 'State/selectors';

import useLayoutColumns from 'Utils/hooks/useLayout';
import useIsMobile from 'Utils/hooks/useIsMobile';

import styles from './style.module.css';
import DevicesCard from 'Pages/Zones/Components/Devices/Devices card';
import useDevices from 'State/hooks/useDevices';

const PopularDevicesViewAll: FunctionComponent = () => {
  const { t } = useTranslation();
  const [columns, ref] = useLayoutColumns();
  const isMobile = useIsMobile();
  const {
    isMenuOpen,
    handleClick,
    handleOnClose,
    parent,
    sortingType,
    groupingType,
    filterItems,
    allFilterItems,
    menuOpenTrigger,
  } = useDeviceFilters();

  const {
    allDevices: {
      isLoading: isLoadingDevices,
      errorMessage: errorMessageDevices,
    },
    guestDevices,
  } = useDevices();

  const isLoading = isLoadingDevices;
  const errorMessage = errorMessageDevices;

  const selectedGroup = useSelector(
    selectors.ui.devicesAndGroups.selectedGroup
  );

  const getViewAllDeviceTitle = useMemo(() => {
    return t('devices.devices');
  }, []);

  const { title, devices, headerMenuItems } = useMemo(() => {
    let _title = getViewAllDeviceTitle;
    let _devices: ZoneDevice[] = [];
    const _headerMenuItems: MenuItemProps[] = [];

    _devices = [
      ...(guestDevices?.auto || []),
      ...(guestDevices?.approved || []),
      ...(guestDevices?.blocked || []),
    ];

    return {
      title: _title,
      devices: _devices,
      headerMenuItems: _headerMenuItems,
    };
  }, [guestDevices]);

  const groupedAndSortedDevices: [string, ZoneDevice[]][] = useMemo(() => {
    if (!devices) {
      return [];
    }

    const _devices = [...devices];

    const _groupedDevices = groupDevices(
      _devices as DeviceFilters,
      groupingType
    );

    return _groupedDevices.map(([groupLabel, devices]) => {
      return [groupLabel, sortDevices(devices, sortingType)];
    });
  }, [devices, groupingType, sortingType]) as [string, ZoneDevice[]][];

  const allItems = [
    ...allFilterItems.Sort,
    { isDivider: true },
    ...allFilterItems.Group,
  ];

  const commonFilter = { filterKey: 'Filter', label: t('common.filter') };

  return (
    <Page>
      <Background ref={ref}>
        <FullscreenPageHeader
          label={title}
          backRoute={ROUTES.zones.guest.index}
        >
          <Filters
            parent={parent}
            isMenuOpen={isMenuOpen}
            menuItems={isMobile ? allItems : filterItems}
            handleClick={handleClick}
            handleOnClose={handleOnClose}
            filters={isMobile ? [commonFilter] : undefined}
            menuOpenTrigger={menuOpenTrigger}
          />
        </FullscreenPageHeader>

        <div className={styles.contentWrapper}>
          {groupedAndSortedDevices.map((displayGroup, i) => {
            const [title, devices] = displayGroup;
            return (
              <React.Fragment key={i}>
                <DevicesCard
                  group={selectedGroup ? selectedGroup : undefined}
                  className={styles.deviceCard}
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                  columnSpan={columns}
                  title={title}
                  devices={devices ?? []}
                  headerMenuItems={headerMenuItems}
                  infoTooltipDisabled
                />
              </React.Fragment>
            );
          })}
        </div>
      </Background>
    </Page>
  );
};

export default PopularDevicesViewAll;
