import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { SymbolType } from 'recharts/types/util/types';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  Surface,
  Symbols,
  XAxis,
  YAxis,
} from 'recharts';

import { XAxisTick, YAxisTick } from 'UI/Components/Graphs/BarGraph';
import { BarGraphData } from 'UI/Components/Graphs/types';

import Tooltip from 'UI/Elements/Tooltip';
import { Body1 } from 'UI/Elements/Typography';

import { SimpleBarChartColors } from 'Consts/defintions';
import colorStyles from 'Styles/colors.module.css';

import { Maybe } from 'Consts/types';
import styles from './style.module.css';

const MAX_TICKS_TO_SHOW = 7;

type SimpleBarChartProps = {
  data: BarGraphData<string>[];
  height: number;
  bar1?: Record<string, string>;
  bar2?: Record<string, string>;
  noYAxisDecimals?: boolean;
  keyLabels?: Record<string, string>;
  ariaLabel: string;
};

const renderLegend = (
  payload: Payload[],
  keyLabels: Maybe<Record<string, string>>
) => (
  <div className={styles.wrapper}>
    <ul>
      {payload.map(({ value, color, type }: Payload) => (
        <span key={value}>
          <li className={styles.listItem}>
            <Surface
              width={10}
              height={10}
              viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
            >
              <Symbols
                cx={5}
                cy={5}
                type={type as SymbolType}
                size={50}
                fill={color}
              />
              <Symbols
                cx={5}
                cy={5}
                type={type as SymbolType}
                size={25}
                fill={color}
              />
            </Surface>
            <Body1 className={cn(styles.legendText, colorStyles.still400)}>
              {keyLabels ? keyLabels[value] : value}
            </Body1>
          </li>
        </span>
      ))}
    </ul>
  </div>
);

const SimpleBarChart: FunctionComponent<SimpleBarChartProps> = ({
  data,
  height,
  bar1,
  bar2,
  noYAxisDecimals,
  keyLabels,
  ariaLabel,
}) => {
  const interval =
    data.length > MAX_TICKS_TO_SHOW
      ? Math.floor(data.length / MAX_TICKS_TO_SHOW)
      : 0;

  return (
    <div className={styles.barGraph} aria-label={ariaLabel}>
      <ResponsiveContainer width={'100%'} height={height}>
        <BarChart
          barGap={1}
          data={data.map(({ name, values }) => ({ name, ...values }))}
          margin={{ top: 5, right: 10, left: 25, bottom: 0 }}
          accessibilityLayer
        >
          <CartesianGrid strokeDasharray="1 1" />

          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={<XAxisTick />}
            interval={interval}
          />

          <YAxis
            tickLine={{ stroke: SimpleBarChartColors.tickLine }}
            axisLine={false}
            fontSize={10}
            tick={<YAxisTick />}
            width={10}
            allowDecimals={!noYAxisDecimals}
          />

          <ChartTooltip
            cursor={{ fill: 'transparent' }}
            content={({ label }) => <Tooltip>{label}</Tooltip>}
          />

          <Legend
            align="left"
            iconType="circle"
            content={(props) => renderLegend(props.payload || [], keyLabels)}
          />

          <Bar
            stackId="a"
            barSize={6}
            radius={[5, 5, 5, 5]}
            dataKey={bar1?.key || ''}
            fill={bar1?.color || SimpleBarChartColors.fillOne}
          />

          <Bar
            stackId="b"
            barSize={3.5}
            radius={[3, 3, 3, 3]}
            dataKey={bar2?.key || ''}
            fill={bar2?.color || SimpleBarChartColors.fillTwo}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimpleBarChart;
