import Icon, { IconNames, Icons } from 'UI/Elements/Icon';
import { Body1, Body2, Heading3Medium } from 'UI/Elements/Typography';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.css';
import colorStyles from 'Styles/colors.module.css';

export type KeyCoverageIconType = {
  generalLiability: keyof typeof Icons;
  professionalLiability: keyof typeof Icons;
  workersComp: keyof typeof Icons;
  commercialProperty: keyof typeof Icons;
  businessOwner: keyof typeof Icons;
  cyber: keyof typeof Icons;
};

export type KeyCoverageCopyType = {
  generalLiability: string;
  professionalLiability: string;
  workersComp: string;
  commercialProperty: string;
  businessOwner: string;
  cyber: string;
};

export const keyCoverageOptionsIcons: KeyCoverageIconType = {
  generalLiability: IconNames.Shield64,
  professionalLiability: IconNames.Guard64,
  workersComp: IconNames.Wrench,
  commercialProperty: IconNames.CommercialProperty,
  businessOwner: IconNames.Briefcase,
  cyber: IconNames.Cyber,
};

export const keyCoverageOptionsCopy = {
  generalLiability: {
    body1: t('insurance.generalLiabilityTitle'),
    body2: t('insurance.generalLiabilityParagraph'),
  },
  professionalLiability: {
    body1: t('insurance.professionalLiabilityTitle'),
    body2: t('insurance.professionalLiabilityParagraph'),
  },
  workersComp: {
    body1: t('insurance.workersCompTitle'),
    body2: t('insurance.workersCompParagraph'),
  },
  commercialProperty: {
    body1: t('insurance.commercialPropertyTitle'),
    body2: t('insurance.commercialPropertyParagraph'),
  },
  businessOwner: {
    body1: t('insurance.businessOwnerTitle'),
    body2: t('insurance.businessOwnerParagraph'),
  },
  cyber: {
    body1: t('insurance.cyberTitle'),
    body2: t('insurance.cyberParagraph'),
  },
};

const KeyCoverage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.keyCoverageOptions}>
      <Heading3Medium>{t('insurance.keyCoverageOptions')}</Heading3Medium>
      <ul className={styles.keyCoverageOptionsList}>
        {Object.keys(keyCoverageOptionsIcons).map((icon) => {
          return (
            <li className={styles.keyCoverageOptionsListItem} key={icon}>
              <div className={styles.keyCoverageIconWrapper}>
                <Icon
                  name={
                    keyCoverageOptionsIcons[icon as keyof KeyCoverageIconType]
                  }
                />
              </div>
              <div
                className={styles.keyCoverageOptionsCopy}
                data-testid="key-coverage-options-copy"
              >
                <Body1>
                  {
                    keyCoverageOptionsCopy[icon as keyof KeyCoverageCopyType]
                      .body1
                  }
                </Body1>
                <Body2 className={colorStyles.still500}>
                  {
                    keyCoverageOptionsCopy[icon as keyof KeyCoverageCopyType]
                      .body2
                  }
                </Body2>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default KeyCoverage;
