import React, {
  FunctionComponent,
  Fragment,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import type {
  SecurityPolicy,
  DeviceSecurityPolicy,
  ZoneDevice,
  AppliesToAllDevices,
  Person,
} from 'Consts/types';

import SettingsSectionHeader from 'UI/Components/Headers/SettingsSectionHeader';
import StandardListItem from 'UI/Components/Lists/List standard';
import cn from 'classnames';
import { Card } from 'UI/Elements/Card';
import { IconNames, IconName } from 'UI/Elements/Icon';
import { Body3 } from 'UI/Elements/Typography';

import { ALL_SETTINGS } from '../../../definitions';
import { ActiveProps } from '../../../';

import { ShieldItems } from 'State/slices/settings/securityPolicySlice';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import type { Data } from 'State/utils';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import ShieldActions from './Actions';
import { makeTextExcerpt } from 'Utils/formatters/string';
import useEmployees from 'State/hooks/useEmployees';
import useNavigateToEmployee from 'Utils/hooks/useNavigateToEmployee';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

const BELL = 'bell';

type ListItemProps = {
  i18labelKey: string;
  iconName: IconName;
  rightLabel?: boolean;
  key: ShieldItems;
  i18paragraphKey: string;
};

type ShieldListProps = {
  [key: string]: {
    i18titleKey: string;
    list: ListItemProps[];
  };
};

export type ShieldUpdateProps = { key: ShieldItems; value: boolean };

const SHIELD_SETTINGS: ShieldListProps = {
  digitalAccess: {
    i18titleKey: 'settings.digital',
    list: [
      {
        iconName: IconNames.ClockHistory,
        i18labelKey: 'settings.appTimeLabel',
        key: 'appTime',
        i18paragraphKey: 'settings.appTimeParagraph',
      },
      {
        iconName: IconNames.SecurityCheckList,
        i18labelKey: 'settings.appPermissionsLabel',
        rightLabel: true,
        key: 'workAppropriate',
        i18paragraphKey: 'settings.appPermissionsParagraph',
      },
    ],
  },
  shield: {
    i18titleKey: 'settings.shield',
    list: [
      {
        iconName: IconNames.Shield,
        i18labelKey: 'settings.secureAndProtectLabel',
        key: 'secureAndProtect',
        i18paragraphKey: 'settings.secureAndProtectParagraph',
      },
      {
        iconName: IconNames.SecurityMask,
        i18labelKey: 'settings.iotProtectLabel',
        key: 'iotProtect',
        i18paragraphKey: 'settings.iotProtectParagraph',
      },
      {
        iconName: IconNames.HandSecurity,
        i18labelKey: 'settings.adBlockingLabel',
        key: 'adBlocking',
        i18paragraphKey: 'settings.adBlockingParagraph',
      },
    ],
  },
};

const SHIELD_SETTINGS_ENTRIES = Object.entries(SHIELD_SETTINGS);

type ListItemComponentProps = ListItemProps & {
  checked?: boolean;
  disabled?: boolean;
  securityPolicyData?: Data<SecurityPolicy>;
  custom?: boolean;
  security: 'general' | 'device' | 'person';
  handleLabelClick: React.MouseEventHandler;
};

const ListItem: FunctionComponent<ListItemComponentProps> = ({
  i18labelKey,
  i18paragraphKey,
  rightLabel,
  iconName,
  checked,
  disabled,
  handleLabelClick,
  custom,
}) => {
  const { t } = useTranslation();

  const handleworkdpiContentFilteringCustomLabel = () => {
    if (custom) {
      return t('settings.custom');
    }

    if (checked) {
      return makeTextExcerpt(t('settings.workAppropriate'), 16);
    } else {
      return t('settings.noLimits');
    }
  };

  const handleCustomLabel = () => {
    if (custom) {
      return t('settings.custom');
    }

    if (checked) {
      return t('common.on');
    } else {
      return t('common.off');
    }
  };

  return (
    <StandardListItem
      L1Props={{ iconProps: { name: iconName } }}
      L2Props={{
        label: t(i18labelKey),
        paragraph: t(i18paragraphKey),
      }}
      RProps={
        rightLabel
          ? {
              label: handleworkdpiContentFilteringCustomLabel(),
              className: cn({
                [styles.blue]: !disabled,
                [styles.disabled]: disabled,
              }),
              labelOnClick: !disabled ? handleLabelClick : undefined,
              ariaLabel: t(i18labelKey),
            }
          : {
              label: handleCustomLabel(),
              className: cn({
                [styles.blue]: !disabled,
                [styles.disabled]: disabled,
              }),
              labelOnClick: !disabled ? handleLabelClick : undefined,
              ariaLabel: t(i18labelKey),
            }
      }
      ariaLabel=""
    />
  );
};

type ShieldSettingsProps = ActiveProps & {
  locationName: string;
  shieldSettingsData:
    | ReturnType<typeof selectors.settings.securityPolicy.shieldSettings>
    | ReturnType<typeof selectors.deviceSecurityPolicy.deviceShieldSettings>
    | ReturnType<typeof selectors.personSecurityPolicy.personShieldSettings>;
  securityPolicyData?: Data<SecurityPolicy>;
  locationSecurityAppliesToAllDevices?: Data<AppliesToAllDevices>;
  deviceSecurityPolicyData?: Data<DeviceSecurityPolicy>;
  personSecurityPolicyData?: Data<SecurityPolicy>;
  security: 'general' | 'device' | 'person';
  device?: ZoneDevice;
  person?: Person;
  showShieldText?: boolean;
  shieldSettingsForDevice?: boolean;
};

const ShieldSettings: FunctionComponent<ShieldSettingsProps> = ({
  setActive,
  locationName,
  securityPolicyData,
  locationSecurityAppliesToAllDevices,
  deviceSecurityPolicyData,
  personSecurityPolicyData,
  shieldSettingsData,
  security,
  device,
  person,
  showShieldText = true,
  shieldSettingsForDevice = false,
}) => {
  const [actionType, setActionType] = useState<ShieldUpdateProps | null>(null);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const dispatch = useDispatch<AppDispatch>();
  const parentRef = useRef<Element | null>(null);
  const { t } = useTranslation();
  const persons = useEmployees();
  const navigateToEmployee = useNavigateToEmployee();
  const devicePerson = useMemo(() => {
    return persons.data?.filter((p) => device?.personId === p.id) || null;
  }, [persons.data, device?.personId]);
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  const { data: shieldSettings, isLoading, errorMessage } = shieldSettingsData;

  const handleHide = useCallback(() => {
    setActionType(null);
  }, []);

  const navigateTo = useCallback((personId: string | undefined) => {
    if (!personId) {
      return;
    }
    navigateToEmployee(personId);
  }, []);

  const onChange = useCallback(
    ({ key, value }: ShieldUpdateProps) => {
      if (key === 'appTime' && security === 'general') {
        dispatch(actions.locations.updateActiveLocationAppTime(value));
      } else if (key === 'appTime' && security === 'device' && device?.mac) {
        dispatch(
          actions.deviceSecurityPolicy.updateActiveDeviceAppTime(
            value,
            device?.mac
          )
        );
      } else if (key === 'appTime' && security === 'person' && person?.id) {
        dispatch(
          actions.personSecurityPolicy.updateActivePersonAppTime(
            value,
            person.id
          )
        );
      }

      if (security === 'general' && key !== 'appTime') {
        dispatch(
          actions.settings.securityPolicy.updateLocationSecurityPolicy({
            [key]: value,
          })
        );
      } else if (security === 'device' && device?.mac && key !== 'appTime') {
        dispatch(
          actions.deviceSecurityPolicy.updateDeviceSecurityPolicy(device?.mac, {
            [key]: value,
          })
        );
      } else if (security === 'person' && person?.id && key !== 'appTime') {
        dispatch(
          actions.personSecurityPolicy.updatePersonSecurityPolicy(person.id, {
            [key]: value,
          })
        );
      }
      handleHide();
    },
    [dispatch, handleHide]
  );

  const handleLabelClick = useCallback(
    (ev: React.MouseEvent, key: ShieldItems) => {
      setActionType({
        key: key,
        value: shieldSettings[key],
      });
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
      parentRef.current = ev.currentTarget;
    },
    []
  );

  const handleCardOnClick = useCallback(() => {
    setActive?.(ALL_SETTINGS.shield.uniqueKey);
  }, [setActive]);

  return (
    <div
      role="region"
      aria-labelledby={t('settings.shield')}
      className={cn({
        [styles.shieldSettingsForDevice]: shieldSettingsForDevice,
        [styles.shieldSettingsForDeviceBell]: isBell,
      })}
    >
      <Card
        isLoading={
          (isLoading ||
            securityPolicyData?.isLoading ||
            deviceSecurityPolicyData?.isLoading ||
            personSecurityPolicyData?.isLoading) &&
          (!shieldSettings || !securityPolicyData?.data)
        }
        errorMessage={errorMessage || securityPolicyData?.errorMessage}
        header={{
          L2Props: {
            label: t('settings.shield'),
          },
        }}
        onClick={handleCardOnClick}
      >
        {device?.personId ? (
          <div className={styles.bodyTopText}>
            <Body3 className={colorStyles.still400}>
              {t('settings.assignedDeviceShieldText', {
                personName: devicePerson?.[0].nickname,
              })}
              <span
                className={styles.link}
                onClick={() => navigateTo(devicePerson?.[0].id)}
              >
                {' '}
                {t('settings.profileLink')}{' '}
              </span>
            </Body3>
          </div>
        ) : (
          showShieldText && (
            <div className={styles.bodyTopText}>
              <Body3 className={colorStyles.still400}>
                {t('settings.shieldText', {
                  locationName: makeTextExcerpt(locationName, 20),
                })}
              </Body3>
            </div>
          )
        )}

        {SHIELD_SETTINGS_ENTRIES.map(([key, { i18titleKey, list }]) => {
          return (
            <div key={key}>
              <SettingsSectionHeader
                LProps={{
                  smallLabel: t(i18titleKey),
                }}
                RProps={{ withDivider: true }}
              />

              {list.map(({ key, ...props }, i) => {
                const checked = shieldSettings[key];
                const disabled = device?.personId;
                const custom =
                  !locationSecurityAppliesToAllDevices?.data?.[key] &&
                  security === 'general';
                return (
                  <Fragment key={`${key}_${i}`}>
                    <ListItem
                      {...props}
                      checked={!!checked}
                      securityPolicyData={securityPolicyData}
                      security={security}
                      disabled={!!disabled}
                      handleLabelClick={(ev) => handleLabelClick(ev, key)}
                      key={key}
                      custom={custom ? true : false}
                    />
                  </Fragment>
                );
              })}
            </div>
          );
        })}
      </Card>

      <ShieldActions
        type={actionType}
        onClose={handleHide}
        onChange={onChange}
        security={security}
        parent={parentRef.current}
        shield={shieldSettings}
        securityPolicyData={securityPolicyData}
        locationSecurityAppliesToAllDevices={
          locationSecurityAppliesToAllDevices
        }
        menuOpenTrigger={menuOpenTrigger}
      />
    </div>
  );
};

export default ShieldSettings;
