import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Body2, Heading2Medium, Heading3Medium } from 'UI/Elements/Typography';
import Icon, { Props as IconProps } from 'UI/Elements/Icon';
import PillLarge, { Props as PillProps } from 'UI/Elements/Pill large';
import PillWeb, { Props as PillWebProps } from 'UI/Elements/Pill web';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { Maybe } from '../../../../Consts/types';

export const PillButton = ({ ...props }: PillProps) => {
  return (
    <PillLarge {...props} className={cn(styles.buttonPill, props?.className)} />
  );
};

export const WebPillButton = ({ ...props }: PillWebProps) => {
  return (
    <PillWeb
      {...props}
      className={cn(styles.webButtonPill, props?.className)}
    />
  );
};

export type LProps = {
  label: Maybe<string>;
  paragraph?: string;
  iconProps?: IconProps;
  className?: string;
};

const L: FunctionComponent<LProps> = ({
  label,
  paragraph,
  iconProps,
  className,
}) => {
  return (
    <div className={cn(styles.l, className)}>
      {iconProps && <Icon {...iconProps} />}

      <div>
        <Heading2Medium className={styles.header}>{label}</Heading2Medium>
        <Heading3Medium className={styles.mobileHeader}>{label}</Heading3Medium>
        {paragraph && (
          <Body2 className={colorStyles.still400}>{paragraph}</Body2>
        )}
      </div>
    </div>
  );
};

export type RProps = {
  labelA1?: string;
  labelA2?: string;
  labelB1?: string;
  labelB2?: string;
  avatarProps?: IconProps;
  icon1Props?: IconProps;
  icon2Props?: IconProps;
  buttonPillProps?: PillProps;
  webButtonPillProps?: PillWebProps;
};

const R: FunctionComponent<RProps> = ({
  labelA1,
  labelA2,
  labelB1,
  labelB2,
  avatarProps,
  icon1Props,
  icon2Props,
  buttonPillProps,
  webButtonPillProps,
}) => {
  return (
    <>
      {(labelA1 || labelA2) && (
        <div className={styles.pillGroup}>
          {labelA1 && <PillLarge label={labelA1} />}
          {labelA2 && <PillLarge label={labelA2} />}
        </div>
      )}

      {(labelB1 || labelB2) && (
        <div className={styles.pillGroup}>
          {labelB1 && <PillLarge label={labelB1} />}
          {labelB2 && <PillLarge label={labelB2} />}
        </div>
      )}

      {avatarProps && <Icon {...avatarProps} />}
      {icon1Props && <Icon {...icon1Props} />}
      {icon2Props && <Icon {...icon2Props} />}
      {buttonPillProps && <PillButton {...buttonPillProps} />}
      {webButtonPillProps && <WebPillButton {...webButtonPillProps} />}
    </>
  );
};

type BaseProps = {
  className?: string;
  LProps: LProps;
  children?: React.ReactNode;
};

export const ScreenHeaderBase: FunctionComponent<BaseProps> = ({
  className,
  LProps,
  children,
}) => {
  return (
    <div className={cn(styles.screenHeader, className)} role="heading">
      <L {...LProps} />
      {children && <div className={styles.r}>{children}</div>}
    </div>
  );
};

type ScreenHeaderProps = {
  className?: string;
  LProps: LProps;
  RProps?: RProps;
};

const ScreenHeader: FunctionComponent<PropsWithChildren<ScreenHeaderProps>> = ({
  className,
  LProps,
  children,
  RProps = {},
}) => {
  return (
    <ScreenHeaderBase className={className} LProps={LProps}>
      <R {...RProps} />
      {children}
    </ScreenHeaderBase>
  );
};

export default ScreenHeader;
