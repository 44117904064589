import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';
import { Body1, Heading3Medium } from 'UI/Elements/Typography';

import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type ButtonTheme = 'black' | 'white' | 'noborder';

export const BUTTON_THEMES: Record<ButtonTheme, ButtonTheme> = {
  black: 'black',
  white: 'white',
  noborder: 'noborder',
};

export type ButtonTypeface = 'body1' | 'heading3m' | 'unstyled';

export const BUTTON_TYPEFACES: Record<ButtonTypeface, ButtonTypeface> = {
  body1: 'body1',
  heading3m: 'heading3m',
  unstyled: 'unstyled',
};

export type Props = {
  label: string;
  className?: string;
  disabled?: boolean;
  theme?: ButtonTheme;
  typeface?: ButtonTypeface;
  fullWidth?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  prefixIcon?: IconName;
  shouldAutoFocus?: boolean;
};

const Button: FunctionComponent<Props> = ({
  label,
  disabled = false,
  theme = BUTTON_THEMES.black,
  typeface = BUTTON_TYPEFACES.body1,
  fullWidth,
  onClick,
  className,
  prefixIcon,
  shouldAutoFocus,
}) => {
  const themeStyle = {
    [styles.black]: theme === BUTTON_THEMES.black,
    [styles.white]: theme === BUTTON_THEMES.white,
    [styles.noborder]: theme === BUTTON_THEMES.noborder,
    [styles.fullWidth]: fullWidth,
  };

  return (
    <button
      className={cn(styles.button, themeStyle, className)}
      onClick={onClick}
      disabled={disabled}
      autoFocus={shouldAutoFocus}
      aria-label={label}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
      tabIndex={0}
    >
      {prefixIcon && <Icon name={prefixIcon} />}
      {typeface === BUTTON_TYPEFACES.body1 && <Body1>{label}</Body1>}
      {typeface === BUTTON_TYPEFACES.heading3m && (
        <Heading3Medium>{label}</Heading3Medium>
      )}
      {typeface === BUTTON_TYPEFACES.unstyled && <div>{label}</div>}
    </button>
  );
};

export default Button;
