import cn from 'classnames';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  CustomerId,
  LocationId,
  OpenTriggerEventType,
  Pod as PodType,
} from 'Consts/types';

import SettingsSectionHeader from 'UI/Components/Headers/SettingsSectionHeader';
import StandardListItem from 'UI/Components/Lists/List standard';
import Menu from 'UI/Components/Menu';

import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';

import * as actions from 'State/actions';
import useCustomer from 'State/hooks/useCustomer';
import useLocations from 'State/hooks/useLocations';
import usePods from 'State/hooks/usePods';
import { AppDispatch } from 'State/store';

import { useSidepanel } from 'Utils/hooks/useSidepanel';

import {
  DeletePod,
  LanEthernetPod,
  PodInfo,
  RenamePod,
} from './Components/Actions';

import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import { Data } from 'State/utils';
import Divider from 'UI/Elements/Divider';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import styles from './style.module.css';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

type PodProps = {
  pod: PodType;
  onClick: (ev: React.MouseEvent<HTMLElement>, pod: PodType) => void;
};

const Pod: FunctionComponent<PodProps> = ({ pod, onClick }) => {
  const { t } = useTranslation();

  const isConnected = pod.connectionState === 'connected';

  const status = t(
    isConnected
      ? pod.healthStatus
        ? 'healthStatus.' + pod.healthStatus
        : 'healthStatus.unknown'
      : 'common.offline'
  );

  const handleMenuClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      onClick(ev, pod);
    },
    [onClick, pod]
  );
  return (
    <StandardListItem
      key={pod.id}
      L1Props={{
        iconProps: {
          name: isConnected ? IconNames.PodCheck : IconNames.PodIssue,
          className: cn({ [styles.error]: !isConnected }),
        },
      }}
      L2Props={{
        label: pod.nickname ?? pod.defaultName,
        paragraph: pod.capabilities?.disablePorts
          ? pod.ethernetLan?.default?.mode === 'disable'
            ? t('settings.lanEthernetOff')
            : t('settings.lanEthernetOn')
          : undefined,
        className: cn({ [styles.errorText]: !isConnected }),
      }}
      RProps={{
        smallLabel: status,
        icon1Props: {
          name: IconNames.OverflowVertical,
          onClick: handleMenuClick,
          className: styles.actionIcon,
          tooltipLabel: t('common.moreActions'),
          ariaLabel: t('common.moreActions'),
        },
      }}
      ariaLabel={pod.nickname ?? pod.defaultName}
    />
  );
};

type PodsProps = {
  customerId: CustomerId;
  locationId: LocationId;
  podData: Data<Record<string, PodType>>;
  onPodChange: (data: { id: string; nickname: string }) => void;
  onPodDelete: (id: string) => void;
  onPodEthernet: (id: string, lanCurrentlyEnabled: boolean) => void;
};

const PodsUI: FunctionComponent<PodsProps> = ({
  customerId,
  locationId,
  podData,
  onPodChange,
  onPodDelete,
  onPodEthernet,
}) => {
  const { setContent } = useSidepanel();
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [lanIsOpen, setLanIsOpen] = useState(false);
  const [selectedPodLanMode, setSelectedPodLanMode] = useState(true);
  const [selectedPod, setSelectedPod] = useState<PodType | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const parentRef = useRef<Element | null>(null);

  const podCount = podData.data ? Object.values(podData.data).length : 0;

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();

  const handleCloseMenu = useCallback(() => setMenuIsOpen(false), []);

  const handleBuyPodRedirect = useCallback(() => {
    if (!customerId || !locationId) return;

    window.open(
      customerSupportConfigurations?.customerFeatureEnablement
        ?.partnerBuyPodUrlEnabled
        ? customerSupportConfigurations?.customerConfigurations
            ?.partnerBuyPodUrl
        : `http://www.plume.com/store/mobile/buy-pods?customerId=${customerId}&locationId=${locationId}`
    );
  }, [customerId, locationId]);

  const openSidepanel = useCallback(
    (ev: React.MouseEvent) => {
      if (selectedPod) {
        dispatch(
          actions.ui.page.setSidepanelOpenTriggerType(
            ev.type as OpenTriggerEventType
          )
        );
        setContent(<PodInfo pod={selectedPod} />);
      }
    },
    [selectedPod, setContent]
  );

  const handlePodChangeClick = useCallback(() => {
    setRenameIsOpen(true);
  }, []);

  const handlePodDeleteClick = useCallback(() => {
    setDeleteIsOpen(true);
  }, []);

  const handlePodLanClick = useCallback(() => {
    setLanIsOpen(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setRenameIsOpen(false);
    setDeleteIsOpen(false);
    setLanIsOpen(false);
  }, []);

  const handlePodClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, pod: PodType) => {
      parentRef.current = ev.currentTarget;

      setMenuIsOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
      setSelectedPodLanMode(
        pod.ethernetLan?.default.mode === 'disable' ? false : true
      );
      setSelectedPod(pod);
    },
    []
  );

  const items = [
    {
      label: t('settings.renamePod', { ns: namespace }) || 'Rename pod',
      iconName: IconNames.Edit,
      onClick: handlePodChangeClick,
    },
    {
      label: t('settings.hardwareInfo'),
      iconName: IconNames.CircleInfo,
      onClick: openSidepanel,
    },
    ...(selectedPod?.capabilities?.disablePorts
      ? [
          {
            label: t('settings.lanEthernetPorts'),
            subtitle: t('settings.lanEthernetPortsSubtitle'),
            toggle: {
              disabled: false,
              checked: selectedPodLanMode,
              onChange: () => handlePodLanClick(),
            },
            onClick: () => handlePodLanClick(),
          },
        ]
      : []),
    ...(customerSupportConfigurations?.customerFeatureEnablement
      ?.removePodEnabled
      ? [
          {
            label: t('settings.deletePod', { ns: namespace }) || 'Delete pod',
            theme: 'danger',
            iconName: IconNames.X,
            onClick: handlePodDeleteClick,
          },
        ]
      : []),
  ];

  if (podData.isLoading || podData.errorMessage || !podData.data) {
    return (
      <Card
        isLoading={podData.isLoading}
        errorMessage={podData.errorMessage}
        emptyCardLabel={
          t('settings.noPodsFound', { ns: namespace }) || 'No pods found'
        }
      />
    );
  }

  return (
    <div
      className={styles.pods}
      role="region"
      aria-labelledby={t('settings.pods')}
    >
      <Card noBottomPadding>
        {Object.values(podData.data).map((pod, index) => {
          return (
            <Fragment key={pod.id + index}>
              <Pod key={pod.id} pod={pod} onClick={handlePodClick} />
              {index < podCount - 1 && <Divider key={index} />}
            </Fragment>
          );
        })}
      </Card>

      {customerSupportConfigurations?.customerFeatureEnablement
        ?.buyPodEnabled && (
        <div>
          <SettingsSectionHeader
            LProps={{
              smallLabel:
                t('settings.extendWifiCoverage', { ns: namespace }) ||
                'Extend your WiFi coverage',
            }}
          />
          <Card noBottomPadding>
            <StandardListItem
              onClick={handleBuyPodRedirect}
              L1Props={{
                iconProps: { name: IconNames.Cart },
              }}
              L2Props={{
                label: t('settings.buyPod', { ns: namespace }) || 'Buy a pod',
              }}
              RProps={{ icon1Props: { name: IconNames.OpenBrowser } }}
              ariaLabel={t('settings.buyPod', { ns: namespace }) || 'Buy a pod'}
            />
          </Card>
        </div>
      )}

      <Menu
        isOpen={menuIsOpen}
        parent={parentRef.current}
        items={items}
        onClose={handleCloseMenu}
        openTriggerEventType={menuOpenTrigger}
      />

      <RenamePod
        isOpen={!!(renameIsOpen && selectedPod)}
        pod={selectedPod}
        onPodChange={onPodChange}
        onClose={handleCloseAlert}
      />

      <DeletePod
        isOpen={!!(deleteIsOpen && selectedPod)}
        pod={selectedPod}
        onPodDelete={onPodDelete}
        onClose={handleCloseAlert}
      />

      <LanEthernetPod
        isOpen={!!(lanIsOpen && selectedPod)}
        pod={selectedPod}
        onToggleEthernet={onPodEthernet}
        onClose={handleCloseAlert}
        lanCurrentlyEnabled={selectedPodLanMode}
      />
    </div>
  );
};

const Pods: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { data: customer } = useCustomer();
  const { activeLocation } = useLocations();

  const podData = usePods();

  const onPodChange = useCallback(
    (data: { id: string; nickname: string }) => {
      dispatch(actions.settings.pods.updatePod(data));
    },
    [dispatch]
  );

  const onPodDelete = useCallback(
    (id: string) => {
      dispatch(actions.settings.pods.deletePod(id));
    },
    [dispatch]
  );

  const onPodEthernet = useCallback(
    (id: string, lanCurrentlyEnabled: boolean) => {
      dispatch(
        actions.settings.pods.ethernetTogglePod(
          id,
          lanCurrentlyEnabled ? 'disable' : 'auto'
        )
      );
    },
    [dispatch]
  );

  return (
    <PodsUI
      customerId={customer?.id ?? ''}
      locationId={activeLocation.data?.id ?? ''}
      podData={podData}
      onPodChange={onPodChange}
      onPodDelete={onPodDelete}
      onPodEthernet={onPodEthernet}
    />
  );
};

export default Pods;
