import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { PERIODS } from 'Consts/defintions';
import {
  GuestNetworkUsage,
  GuestNetworkUsageHourStats,
  Nullable,
} from 'Consts/types';

import SimpleBarChart from 'UI/Components/Graphs/SimpleBarChart';
import { BarGraphData } from 'UI/Components/Graphs/types';

import { Card } from 'UI/Elements/Card';

import { formatGraphDateLabel } from 'Utils/formatters/date';

import { GuestsByHourGraphBarColors } from 'Consts/defintions';
import { t } from 'i18next';

type GuestByHourProps = {
  data: Nullable<GuestNetworkUsage>;
};

type GuestBarType = 'repeat' | 'new';

const bar1 = {
  key: 'repeat',
  color: GuestsByHourGraphBarColors.bar1,
};
const bar2 = { key: 'new', color: GuestsByHourGraphBarColors.bar2 };

const labelKeyToLabel: Record<GuestBarType, string> = {
  repeat: t('guestZone.repeat'),
  new: t('guestZone.new'),
};
const statsToData = ({
  newGuestVisits,
  repeatGuestVisits,
}: GuestNetworkUsageHourStats): BarGraphData<'repeat'>[] => {
  const newVisits = [...newGuestVisits].reverse();
  const repeatVisits = [...repeatGuestVisits].reverse();

  return newVisits.map(({ startTime, visitCount }, i) => {
    const name = formatGraphDateLabel(new Date(startTime), PERIODS.day);

    return {
      name,
      values: {
        new: visitCount,
        repeat: repeatVisits[i].visitCount,
      },
    };
  });
};

const GuestsByHour: FunctionComponent<GuestByHourProps> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) {
    // TODO add loader
    return null;
  }

  const barGraph = statsToData(data.hourlyStats);

  return (
    <Card
      header={{
        L2Props: {
          label: t('guestZone.guestsByHour'),
        },
      }}
    >
      <SimpleBarChart
        data={barGraph}
        height={185}
        bar1={bar1}
        bar2={bar2}
        noYAxisDecimals={true}
        keyLabels={labelKeyToLabel}
        ariaLabel={t('guestZone.guestsByHourChartAriaLabel')}
      />
    </Card>
  );
};

export default GuestsByHour;
