import InputListItem from 'UI/Components/Lists/List input';
import { IconNames } from 'UI/Elements/Icon';
import { Heading2Medium } from 'UI/Elements/Typography';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import * as actions from 'State/actions';
import useEmployeeLoginDatabase from 'State/hooks/useEmployeeLoginDatabase';
import { AppDispatch } from 'State/store';

const EmployeeLoginMobilePreview: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  useEmployeeLoginDatabase();
  const companyName = useSelector(selectors.settings.employeeLogin).data
    ?.localData.companyName;

  const sidepanelOpen = useSelector(selectors.ui.page.sidepanelOpen);

  useEffect(() => {
    dispatch(actions.settings.employeeLogin.fetchData());
  }, [sidepanelOpen]);

  return (
    <div
      className={styles.employeeLoginMobilePreview}
      role="region"
      aria-labelledby={t('settings.employeeLogin')}
    >
      <div className={styles.employeeLoginMobilePreviewCompanyInfo}>
        <Heading2Medium
          className={styles.employeeLoginMobilePreviewCompanyName}
        >
          {companyName}
        </Heading2Medium>
      </div>
      <div className={styles.employeeLoginMobilePreviewForm}>
        <InputListItem
          placeholder={t('settings.countryCode')}
          prefixIcon={IconNames.Map}
          inputType="dropdown"
          value=""
          disabled
        />
        <InputListItem
          placeholder={t('settings.phoneNumber')}
          value=""
          prefixIcon={IconNames.Phone}
          disabled
        />
      </div>
    </div>
  );
};

export default EmployeeLoginMobilePreview;
