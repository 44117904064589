import cx from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import useIsMobile from '../../../Utils/hooks/useIsMobile';
import Icon, { Props as IconProps } from '../../Elements/Icon';
import PendingContent from '../PendingContent/PendingContent';
import { AutoCompleteContext } from './context';
import { useClickOutside } from './hooks/useClickOutside';
import styles from './style.module.css';
import { filteredList } from './helpers/filteredList.helper';
import { useListItemRefs } from './hooks/useListItemRefs';

export type AutoCompleteResultsItem = {
  id: string;
  label: string;
  type?: string;
  searchItem?: string;
};

export type AutoCompleteResultsProps = {
  items?: AutoCompleteResultsItem[];
  maxHeight?: number;
  onItemClick?: (item: AutoCompleteResultsItem) => void;
  toggle: (state: boolean) => void;
  isOpen: boolean;
  resultsClassname?: string;
  isShieldAutoComplete?: boolean;
  focusFirstItem?: boolean;
  setActiveDescendantId: (id: string) => void;
};

type AutoCompleteResultItemProps = {
  item: AutoCompleteResultsItem;
  type: string;
  onItemClick: (item: AutoCompleteResultsItem) => void;
  isShieldAutoComplete?: boolean;
  resultsIndex: number;
  isActive: boolean;
  resultsRef: React.Ref<HTMLDivElement>;
};

const AutoCompleteResultItem: FunctionComponent<
  AutoCompleteResultItemProps
> = ({
  item,
  type,
  onItemClick,
  isShieldAutoComplete,
  resultsRef,
  isActive,
}) => {
  const mapResultTypeToIcon: Record<string, IconProps['name']> = {
    device: 'LaptopAndPhone',
    person: 'Person',
  };
  const iconName = mapResultTypeToIcon[type] || 'Person';

  return (
    <div
      ref={resultsRef}
      id={isActive ? 'selectedItem' : ''}
      onClick={() => onItemClick(item)}
      className={cx(styles.autoCompleteResultsItem, {
        [styles.shieldAutoCompleteResultsItem]: isShieldAutoComplete,
        [styles.autoCompleteResultsItemActive]: isActive,
      })}
      role="option"
      tabIndex={isActive ? 0 : -1}
      aria-selected={isActive}
    >
      <Icon name={iconName} className={styles.autoCompleteResultsItemIcon} />
      <div
        className={styles.autoCompleteResultsItemLabel}
        data-testid="autoComplete-results-item"
        id={item.id}
      >
        <p aria-live="polite" role="status">
          {item.label}
        </p>
      </div>
    </div>
  );
};

const AutoCompleteResults: FunctionComponent<AutoCompleteResultsProps> = ({
  items,
  maxHeight,
  onItemClick,
  toggle,
  isOpen,
  resultsClassname,
  isShieldAutoComplete = false,
  focusFirstItem,
  setActiveDescendantId,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { configuration, isLoading, closeSearchResults, availableFilters } =
    useContext(AutoCompleteContext);
  const isMobile = useIsMobile();
  const { ref } = useClickOutside({
    toggler: () => {
      toggle(false);
    },
    state: isOpen,
  });

  useEffect(() => {
    if (focusFirstItem && items?.length) {
      setActiveIndex(0);
    }
  }, [focusFirstItem, items]);

  const onClick = (item: AutoCompleteResultsItem) => {
    if (configuration.closeOnSelectItem) {
      closeSearchResults();
    }
    onItemClick?.(item);
  };

  const shouldShowNoResultsText = !Boolean(items?.length) && !isLoading;
  const hasResults = Boolean(items?.length);
  const itemsListForRefs = filteredList(items, availableFilters) || [];
  const itemsRefs = useListItemRefs(itemsListForRefs);
  useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      itemsRefs.current[index] = el;
    },
    [itemsRefs]
  );

  useEffect(() => {
    if (
      activeIndex !== null &&
      activeIndex >= 0 &&
      activeIndex < itemsListForRefs.length
    ) {
      itemsRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex, itemsListForRefs]);

  useEffect(() => {
    if (!items || !activeIndex) return;

    setActiveDescendantId(items[activeIndex].id);
  }, [activeIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!items) return;
    switch (event.key) {
      case 'ArrowDown':
        setActiveIndex((prevIndex) => {
          if (prevIndex === null) return 0;
          const nextIndex =
            activeIndex !== items.length - 1 ? prevIndex + 1 : 0;
          return nextIndex;
        });
        event.preventDefault();
        break;
      case 'ArrowUp':
        setActiveIndex((prevIndex) => {
          if (prevIndex === null) return items.length - 1;
          const nextIndex =
            activeIndex !== 0 ? prevIndex - 1 : items.length - 1;
          return nextIndex;
        });
        event.preventDefault();
        break;
      case 'Enter' || ' ' || 'Spacebar':
        if (activeIndex !== null) {
          onClick(items[activeIndex]);
        }
        break;
      case 'Tab':
        if (event.shiftKey) {
          setActiveIndex(null);
        }
        break;
      default:
        break;
    }
  };

  const renderResults = () => {
    if (!hasResults) {
      return null;
    }
    return (
      <div onKeyDown={handleKeyDown} tabIndex={-1} role="listbox">
        {filteredList(items, availableFilters)?.map((listItem, idx) => (
          <AutoCompleteResultItem
            item={listItem}
            type={listItem.type}
            onItemClick={onClick}
            key={listItem.id}
            isShieldAutoComplete={isShieldAutoComplete}
            resultsIndex={idx}
            isActive={activeIndex === idx}
            resultsRef={(el: HTMLDivElement | null) =>
              (itemsRefs.current[idx] = el)
            }
          />
        ))}
      </div>
    );
  };

  const pendingContent = () => (
    <PendingContent loading={isLoading} hideContent>
      {shouldShowNoResultsText && (
        <div
          className={cx(styles.autoCompleteResultsEmpty, {
            [styles.shieldAutoCompleteResultsItem]: isShieldAutoComplete,
          })}
        >
          <p aria-live="polite" role="alert">
            {configuration.noResultsText}
          </p>
        </div>
      )}

      {renderResults()}
    </PendingContent>
  );

  const rootClassNames = cx(styles.autoCompleteResults, {
    [styles.autoCompleteResultsMobile]: isMobile,
  });

  return (
    <div
      className={cx(rootClassNames, resultsClassname)}
      ref={ref}
      role="listbox"
      id="auto-complete-list"
    >
      {maxHeight ? (
        <PerfectScrollbar
          style={{
            maxHeight: `${maxHeight}px`,
          }}
          className={styles.autoCompleteResultsScrollbarContainer}
        >
          {pendingContent()}
        </PerfectScrollbar>
      ) : (
        pendingContent()
      )}
    </div>
  );
};

export default AutoCompleteResults;
