import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Background from 'UI/Layout/Background';
import Page from 'UI/Layout/Page';

import { profilePhoto } from 'Consts/defintions';
import useEmployees from 'State/hooks/useEmployees';
import ScreenHeader from 'UI/Components/Headers/Header screen web';
import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import pageHeaderStyles from 'UI/Layout/Page header/style.module.css';
import useNavigateToEmployee from 'Utils/hooks/useNavigateToEmployee';
import styles from './style.module.css';

import { ROUTES } from 'Consts/routes';
import { Maybe, OpenTriggerEventType, ZoneDevice } from 'Consts/types';
import DailyDataUsageGraphUI from 'Pages/Zones/Components/Data usage';
import AssignDevice from 'Pages/Zones/Components/Devices/Assign devices/AssignDevices';
import DeviceSettings from 'Pages/Zones/Components/Devices/Device Settings /DeviceSettings';
import { useMenuItems } from 'Pages/Zones/Components/Devices/Device menu items';
import MacAndIpAddressSidePanel from 'Pages/Zones/Components/Devices/Mac And IP Address SidePanel';
import OnlineTimeGraph from 'Pages/Zones/Components/Employees/Components/Graph';
import * as actions from 'State/actions';
import useDevice from 'State/hooks/useDevice';
import useDeviceDailyDataUsage from 'State/hooks/useDeviceDailyDataUsage';
import useDeviceDailyOnlineTime from 'State/hooks/useDeviceDailyOnlineTime';
import useDeviceMonthlyDataUsage from 'State/hooks/useDeviceMonthlyDataUsage';
import useDeviceMonthlyOnlineTime from 'State/hooks/useDeviceMonthlyOnlineTime';
import useDeviceSecurityEvents from 'State/hooks/useDeviceSecurityEvents';
import useDevices from 'State/hooks/useDevices';
import { AppDispatch } from 'State/store';
import bellColors from 'Styles/bellColors';
import colors from 'Styles/colors';
import Menu, { MenuItemProps } from 'UI/Components/Menu';
import AlertBar from 'UI/Elements/Alert bar';
import Columns from 'UI/Layout/Columns';
import ShareAccessSidePanel from 'UI/Reusable/ShareAccess/ShareAccessSidePanel';
import ShieldGraphCard from 'UI/Reusable/Shield graph';
import { FilterNamePlusAll } from 'UI/Reusable/Shield graph/utils';
import { formatRelativeToNow } from 'Utils/formatters/date';
import useLayoutColumns from 'Utils/hooks/useLayout';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { useDispatch } from 'react-redux';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import DeviceInfoCard from './Components/DeviceInfoCard';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

const BELL = 'bell';

const Device: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mac } = useParams();
  const persons = useEmployees();
  const [columns, ref] = useLayoutColumns();
  const navigateToEmployee = useNavigateToEmployee();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const parentRef = useRef<Element | null>(null);
  const deviceMac = mac!;
  const daily = useDeviceDailyOnlineTime(deviceMac);
  const { MENU_ITEMS } = useMenuItems({});
  const monthly = useDeviceMonthlyOnlineTime(deviceMac);
  const dailyDataUsage = useDeviceDailyDataUsage(deviceMac);
  const monthlyDataUsage = useDeviceMonthlyDataUsage(deviceMac);
  const dispatch = useDispatch<AppDispatch>();
  const { allDevices: allDevicesData } = useDevices();
  const { data: devicedata, isLoading: isDeviceLoading } = useDevice(deviceMac);
  const { setContent } = useSidepanel();
  const [activeFilter, setActiveFilter] = useState<FilterNamePlusAll>('all');
  const environment = getConfigurationFromDomain();

  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);
  const colorFile = isBell ? bellColors : colors;

  const fullDevice = devicedata ? ([devicedata] as ZoneDevice[]) : null;
  const device = allDevicesData?.data
    ? allDevicesData.data.filter((item) => mac === item.mac)
    : null;
  const { data: deviceSecurityEvents } = useDeviceSecurityEvents(deviceMac);

  const handleMenuOpen = useCallback(
    (ev: React.MouseEvent) => {
      setIsMenuOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
      dispatch(actions.ui.devicesAndGroups.selectDevices(device));
      dispatch(
        actions.ui.devicesAndGroups.selectQuarantineDomain(
          device?.[0]?.quarantineAnomalyBlacklistFqdn || null
        )
      );
      parentRef.current = ev.currentTarget;
    },
    [device]
  );

  const handleOnClose = useCallback(() => {
    setIsMenuOpen(false);
    parentRef.current = null;
  }, []);

  const handleAssignDeviceSidebar = useCallback(
    (ev: React.MouseEvent) => {
      if (!fullDevice?.length) {
        return;
      }

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<AssignDevice device={device!} />);
    },
    [dispatch, device, setContent]
  );

  const handleOpenDeviceSettingsSideBar = useCallback(
    (ev: React.MouseEvent) => {
      if (!fullDevice?.length) {
        return;
      }

      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<DeviceSettings device={fullDevice[0]} />);
    },
    [dispatch, device]
  );

  const handleOpenMacAndIPADressSidebar = useCallback(
    (ev: React.MouseEvent) => {
      if (!device) {
        return;
      }

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );

      setContent(
        <MacAndIpAddressSidePanel
          deviceName={device[0].name}
          mac={device[0].mac}
          ip={device[0].ip}
        />
      );
    },
    [dispatch, device, setContent]
  );

  const handleShareAccess = useCallback(
    (ev: React.MouseEvent) => {
      if (!device) {
        return;
      }
      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<ShareAccessSidePanel device={device[0]} />);
    },
    [setContent, dispatch, device]
  );

  const deviceBlocked =
    device?.[0] && device?.[0]?.networkAccessMode === 'blocked';
  const deviceQuarantined =
    device?.[0] && device?.[0]?.networkAccessMode === 'quarantined';
  const guestDevice = device?.[0] && device?.[0].networkId === 'guest';
  const deviceUnapproved =
    device?.[0] && device?.[0].networkAccessMode === 'auto';

  const menuItems: Maybe<MenuItemProps>[] = [
    ...(deviceBlocked || deviceUnapproved ? [MENU_ITEMS.approveDevice] : []),

    ...(deviceQuarantined
      ? [MENU_ITEMS.unquarantine1hr, MENU_ITEMS.unquarantinePermanently]
      : []),
    ...(guestDevice
      ? []
      : [
          {
            label: t('devices.assignDeviceZone.assignDevice'),
            iconName: IconNames.Person,
            onClick: handleAssignDeviceSidebar,
          },
          {
            label: t('homepage.deviceSettings'),
            iconName: IconNames.Edit,
            onClick: handleOpenDeviceSettingsSideBar,
          },
          {
            label: t('homepage.macAndIp'),
            iconName: IconNames.CircleInfo,
            onClick: handleOpenMacAndIPADressSidebar,
          },
          deviceQuarantined
            ? undefined
            : {
                label: t('devices.shareAccess'),
                iconName: IconNames.ShareAccess,
                onClick: handleShareAccess,
              },
        ]),

    ...(!deviceBlocked && !deviceQuarantined ? [MENU_ITEMS.blockDevice] : []),
  ];

  const person = useMemo(() => {
    if (!device || !persons) return;

    if (device && persons)
      return (
        persons?.data?.filter((p) => device?.[0]?.personId === p.id) || null
      );
    else return undefined;
  }, [persons.data, device]);

  const connectionSince = useMemo(() => {
    if (!device) return '';

    if (device?.[0]?.connectionStateChangeAt) {
      const relativeTime = formatRelativeToNow(
        new Date(device?.[0]?.connectionStateChangeAt)
      );

      if (device?.[0]?.connectionState === 'connected') {
        return t('devices.onlineSince', { time: relativeTime });
      } else {
        return t('homepage.lastOnline', { time: relativeTime });
      }
    }
  }, [device]);

  const deviceDailyData = daily.data?.[deviceMac];
  const deviceMonthlyData = monthly.data?.[deviceMac];

  const deviceDailyDataUsage = dailyDataUsage.data?.[deviceMac] || null;
  const deviceMonthlyDataUsage = monthlyDataUsage.data?.[deviceMac] || null;

  const DeviceDataUsageGraph = (
    <DailyDataUsageGraphUI
      daily={deviceDailyDataUsage}
      monthly={deviceMonthlyDataUsage}
    />
  );

  const DeviceOnlineActivityGraph = (
    <OnlineTimeGraph daily={deviceDailyData} monthly={deviceMonthlyData} />
  );

  const handleSelectFilter = useCallback(
    (name: FilterNamePlusAll) => {
      setActiveFilter((prev) => {
        return prev === name ? 'all' : name;
      });
    },
    [setActiveFilter]
  );

  const handleShieldViewAllClick = useCallback(() => {
    navigate(ROUTES.shield);
  }, [navigate]);

  const DeviceShieldGraph = (
    <ShieldGraphCard
      eventsData={deviceSecurityEvents || []}
      activeTypeFilter={activeFilter}
      onTypeFilterClick={handleSelectFilter}
      onViewAllClick={handleShieldViewAllClick}
    />
  );

  return (
    <Page>
      {deviceMac === device?.[0]?.mac &&
        !isDeviceLoading &&
        (deviceQuarantined || deviceBlocked) && (
          <AlertBar
            backgroundColor={colorFile.sore700}
            iconName={IconNames.HandSecurity}
            text={
              deviceQuarantined
                ? t('devices.alertBar.quarantinedDevice')
                : t('devices.alertBar.blockedDevice')
            }
          />
        )}
      <Background ref={ref}>
        <ScreenHeader
          className={pageHeaderStyles.pageHeader}
          LProps={{
            label: device?.[0]?.nickname || device?.[0]?.name || deviceMac,
            paragraph: connectionSince,
            iconProps: {
              name: IconNames.ChevronLeft,
              onClick: () => {
                navigate(-1);
              },
              tooltipLabel: t('common.goBack'),
              shape: IconShape.square,
            },
          }}
          RProps={{
            avatarProps: person?.[0]?.id
              ? {
                  name: profilePhoto[person?.[0]?.imageId],
                  className: cn(styles.avatarIcon),
                  shape: IconShape.circle,
                  onClick: () => {
                    navigateToEmployee(person?.[0]?.id);
                  },
                }
              : undefined,
            icon1Props: !guestDevice
              ? {
                  name: IconNames.OverflowVertical,
                  onClick: handleMenuOpen,
                  tooltipLabel: '',
                  shape: IconShape.square,
                  ariaLabel: t('common.moreActions'),
                }
              : undefined,
          }}
        />

        <Menu
          isOpen={isMenuOpen}
          parent={parentRef.current}
          items={menuItems}
          onClose={handleOnClose}
          openTriggerEventType={menuOpenTrigger}
        />

        <Columns columnCount={columns} topMargin={16}>
          <DeviceInfoCard device={device?.[0]} />
          {DeviceDataUsageGraph}
          {DeviceOnlineActivityGraph}
          {DeviceShieldGraph}
        </Columns>
      </Background>
    </Page>
  );
};

export default Device;
