import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemProps } from 'UI/Components/Menu';
import { IconNames } from 'UI/Elements/Icon';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

export enum SORTING_TYPES {
  sharedDevices = 'shared devices',
  alphabetically = 'alphabetically',
  recentlyConnected = 'recently connected',
}

export enum GROUPING_TYPES {
  device = 'device',
  ungrouped = 'ungrouped',
}

const useDeviceFilters = (defaultGroup?: GROUPING_TYPES) => {
  const { t } = useTranslation();
  const parentRef = useRef<Element | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const [filterItem, setFilterItem] = useState('');
  const [sortingType, setSortingType] = useState(
    SORTING_TYPES.recentlyConnected
  );
  const [groupingType, setGroupingType] = useState(
    defaultGroup || GROUPING_TYPES.ungrouped
  );

  const handleClick = useCallback((ev: React.MouseEvent, item: string) => {
    setFilterItem(item);
    setIsMenuOpen(true);
    setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    parentRef.current = ev.currentTarget;
  }, []);

  const handleOnClose = useCallback(() => setIsMenuOpen(false), []);

  const filterMenuItems: Record<string, MenuItemProps[]> = useMemo(
    () => ({
      Sort: [
        {
          label: t('devices.sortedAlphabetically'),
          iconName:
            sortingType === SORTING_TYPES.alphabetically
              ? IconNames.Check
              : undefined,
          onClick: () => setSortingType(SORTING_TYPES.alphabetically),
        },
        {
          label: t('devices.sortedByRecentlyConnected'),
          iconName:
            sortingType === SORTING_TYPES.recentlyConnected
              ? IconNames.Check
              : undefined,
          onClick: () => setSortingType(SORTING_TYPES.recentlyConnected),
        },
      ],
      Group: [
        {
          label: t('devices.groupedByDevice'),
          iconName:
            groupingType === GROUPING_TYPES.device
              ? IconNames.Check
              : undefined,
          onClick: () => setGroupingType(GROUPING_TYPES.device),
        },
        {
          label: t('devices.ungrouped'),
          iconName:
            groupingType === GROUPING_TYPES.ungrouped
              ? IconNames.Check
              : undefined,
          onClick: () => setGroupingType(GROUPING_TYPES.ungrouped),
        },
      ],
    }),
    [sortingType, groupingType, t]
  );

  const filterItems = isMenuOpen ? filterMenuItems[filterItem] : [];

  return {
    isMenuOpen,
    handleClick,
    handleOnClose,
    sortingType,
    groupingType,
    parent: parentRef.current,
    filterItems,
    allFilterItems: filterMenuItems,
    menuOpenTrigger,
  };
};

export default useDeviceFilters;
