import Background from 'UI/Layout/Background';
import Page from 'UI/Layout/Page';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import { ScreenHeaderBase } from 'UI/Components/Headers/Header screen web';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { Body1, FinePrint1, Heading3Medium } from 'UI/Elements/Typography';
import colorStyles from 'Styles/colors.module.css';
import KeyCoverage from './Components/KeyCoverage';
import Benefits from './Components/Benefits';
import Divider from 'UI/Elements/Divider';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Consts/routes';
import useIsMobile from 'Utils/hooks/useIsMobile';
import { onEnterKeydown } from 'Utils/keyboardEvents';

const Insurance: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleBtnClick = () => {
    navigate(ROUTES.insurance.next);
  };

  const handleLicenseLinkClick = () => {
    window.open('https://www.nextinsurance.com/insurance-licenses/');
  };

  return (
    <Page>
      <Background contentClassName={styles.insuranceContent}>
        <div className={styles.wrapper}>
          <div className={styles.headerWrapper}>
            <ScreenHeaderBase
              LProps={{
                label: t('common.insurance'),
                paragraph: t('insurance.headerParagraph'),
                className: styles.headerWrapperParagraph,
              }}
            ></ScreenHeaderBase>
            <Icon
              name={
                isMobile
                  ? IconNames.AonAdvancedSetupMobile
                  : IconNames.AonAdvancedSetup
              }
            />
            <Body1 className={colorStyles.still800}>
              {t('insurance.headerDescription')}
            </Body1>
          </div>
          <KeyCoverage />
          <Benefits />
          <div className={styles.footer}>
            <div className={styles.getStartedToday}>
              <Heading3Medium>{t('insurance.getStartedToday')}</Heading3Medium>
              <Body1>{t('insurance.getStartedCopy')}</Body1>
            </div>
            <div className={styles.finePrint}>
              <Divider className={styles.divider} />
              <FinePrint1 className={colorStyles.still600}>
                <span>{t('insurance.finePrint1PreLink')}</span>
                <span
                  tabIndex={0}
                  role="link"
                  className={styles.finePrint1Link}
                  aria-describedby="Licenses link"
                  onClick={handleLicenseLinkClick}
                  onKeyDown={(e) => onEnterKeydown(e, handleLicenseLinkClick)}
                >
                  {t('insurance.finePrint1Link')}
                </span>
                <span>{t('insurance.finePrint1PostLink')}</span>
              </FinePrint1>
              <FinePrint1 className={colorStyles.still600}>
                {t('insurance.finePrint2')}
              </FinePrint1>
              <FinePrint1 className={colorStyles.still600}>
                {t('insurance.finePrint3')}
              </FinePrint1>
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={styles.btn}
              theme={BUTTON_THEMES.black}
              label={t('insurance.exploreCoverageNow')}
              onClick={handleBtnClick}
            />
          </div>
        </div>
      </Background>
    </Page>
  );
};

export default Insurance;
