import {
  SHIELD_EVENTS_CARD_LIMIT,
  SHIELD_EVENTS_PER_EVENTS_CARD_LIMIT,
} from 'Consts/config';
import cn from 'classnames';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { METHODS } from 'Api/index';

import {
  OpenTriggerEventType,
  SecurityEvent,
  SecurityEventType,
  Time,
  ZoneDevice,
} from 'Consts/types';

import { Body1 } from 'UI/Elements/Typography';

import { EventData, SecureAndProtectEventData } from 'Consts/types';

import * as actions from 'State/actions';
import { AppDispatch } from 'State/store';
import { useDispatch } from 'react-redux';

import Alert from 'UI/Components/Alert';
import StandardListItem from 'UI/Components/Lists/List standard';
import { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconNames } from 'UI/Elements/Icon';
import AlertModal from 'UI/Elements/Modals/Alerts';

import { formatRelativeToNow } from 'Utils/formatters/date';

import colorStyles from 'Styles/colors.module.css';

import { categoryToTranslationKey } from 'Consts/shieldEventCategory';
import Card, { V2Card } from 'UI/Elements/Card';
import Grid from 'UI/Layout/Grid';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import V2CardHeader from 'UI/Molecules/CardHeader';
import {
  FilterNamePlusAll,
  SECURITY_EVENT_MAP,
  filterEvents,
} from 'UI/Reusable/Shield graph/utils';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { t } from 'i18next';
import { groupBySecurityKeyValue } from './shieldEventsUtils';
import styles from './style.module.css';

type groupByFqdntype = {
  fqdn: string;
  events: SecurityEvent[];
  isOpen: boolean;
};
const matchEventIcon = (eventType?: string) => {
  if (!eventType) return IconNames.CircleX;
  switch (SECURITY_EVENT_MAP[eventType as SecurityEventType]) {
    case 'onlineProtection':
      return IconNames.Shield;

    case 'contentAccess':
      return IconNames.CircleX;

    case 'remoteAccessProtection':
      return IconNames.CircleInternet;

    case 'advancedIotProtection':
      return IconNames.SecurityMask;

    default:
      return IconNames.HandSecurity;
  }
};

export const deviceName = (devices: ZoneDevice[], macToFind: string) => {
  const foundDevice = devices.find(({ mac }: ZoneDevice) => {
    return mac === macToFind;
  });
  return foundDevice?.nickname || foundDevice?.name || macToFind;
};

type ViewMoreShieldEventsSidePanelProps = {
  cardIndex: number;
  header: string;
  devices: ZoneDevice[];
  events: SecurityEvent[];
  handleToggleOpen: any;
};

const ViewMoreShieldEventsSidePanel: FunctionComponent<
  ViewMoreShieldEventsSidePanelProps
> = ({ header, events, devices, handleToggleOpen, cardIndex }) => {
  return (
    <SimpleSidePanel title={header}>
      <SidePanelContentWrapper>
        <Card noBottomPadding>
          <ShieldEventList
            events={events}
            devices={devices}
            cardIndex={cardIndex}
            handleToggleOpen={handleToggleOpen}
          />
        </Card>
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

type ShieldEventListProps = {
  cardIndex: number;
  events: SecurityEvent[];
  devices: ZoneDevice[];
  handleToggleOpen: any;
};

const ShieldEventList: FunctionComponent<ShieldEventListProps> = ({
  events,
  devices,
  handleToggleOpen,
}) => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const [websiteType, setWebsiteType] = useState<'fqdn' | 'ipv4'>('fqdn');
  const [websiteValue, setWebsiteValue] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState<'approve' | null>(null);
  const { closeSidepanel } = useSidepanel();
  const dispatch = useDispatch<AppDispatch>();
  const onCloseAlert = useCallback(() => {
    setOpenAlert(null);
  }, []);

  const handleEventClick = (
    eventData: EventData | SecureAndProtectEventData,
    createdAt: Time
  ) => {
    setExpanded(createdAt);
    if (eventData?.topLevelDomain) {
      setWebsiteType('fqdn');
      setWebsiteValue(eventData.topLevelDomain);
    } else if (eventData?.fqdn) {
      setWebsiteType('fqdn');
      setWebsiteValue(eventData.fqdn);
    } else if (eventData?.ipaddress) {
      setWebsiteType('ipv4');
      setWebsiteValue(eventData.ipaddress);
    }
    setOpenAlert('approve');
  };

  const handleApprove = useCallback(() => {
    if (websiteType === 'fqdn') {
      dispatch(
        actions.settings.securityPolicy.updateLocationPolicyWhitelist(
          'fqdn',
          websiteValue || '',
          METHODS.POST
        )
      );
    } else if (websiteType === 'ipv4') {
      dispatch(
        actions.settings.securityPolicy.updateLocationPolicyWhitelist(
          'ipv4',
          websiteValue || '',
          METHODS.POST
        )
      );
    }

    handleToggleOpen();
    setOpenAlert(null);
    closeSidepanel();
  }, [dispatch, websiteType, websiteValue]);

  return (
    <>
      <AlertModal isOpen={openAlert === 'approve'} onClose={onCloseAlert}>
        <Alert
          className={styles.domainApprovalModalWebsiteValue}
          topProps={{
            label: t('shield.eventHandling.approveTopLevelDomain', {
              topLevelDomain: websiteValue,
            }),
          }}
          bottomProps={{
            button1Props: {
              label: t('shield.eventHandling.approve'),
              onClick: handleApprove,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: onCloseAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>
      {events.map((event, index) => {
        const isExpanded = expanded === event.createdAt;
        const iconName = matchEventIcon(event.eventType);
        const eventTime = formatRelativeToNow(new Date(event.createdAt));
        const getLabelForType = () => {
          return event.eventType === 'adBlocking'
            ? event.eventData.topLevelDomain
            : deviceName(devices, event.eventData.mac);
        };

        const getParagraphForType = () => {
          return event.eventType === 'adBlocking'
            ? deviceName(devices, event.eventData.mac)
            : event.eventData.topLevelDomain;
        };

        const getParagraphForCategory = () => {
          const categoryKey = categoryToTranslationKey(
            event.eventData?.sourceCategoryId
          );
          if (!categoryKey) return;
          return t(categoryKey);
        };
        return (
          <Fragment key={event.createdAt}>
            <StandardListItem
              className={cn({})}
              L1Props={
                iconName
                  ? {
                      iconProps: {
                        name: iconName,
                        className: styles.eventIcons,
                      },
                    }
                  : {}
              }
              L2Props={{
                className: cn({
                  [colorStyles.still800]: isExpanded,
                }),
                label: getLabelForType(),
                ...(isExpanded
                  ? {
                      paragraph: getParagraphForType(),
                      secondParagraph: getParagraphForCategory(),
                      thirdParagraph: eventTime,
                    }
                  : {
                      paragraph: getParagraphForType(),
                      secondParagraph: getParagraphForCategory(),
                      thirdParagraph: eventTime,
                    }),
              }}
              RProps={{
                icon1Props: {
                  name: IconNames.OverflowVertical,
                  tooltipLabel: t('common.moreActions'),
                  ariaLabel: t('common.moreActions'),
                  className: styles.overflowIcon,
                  onClick: () =>
                    handleEventClick(event.eventData, event.createdAt),
                },
              }}
              ariaLabel={t('shield.events')}
            />
          </Fragment>
        );
      })}
    </>
  );
};

type ShieldEventsProps = {
  columnSpan: 1 | 2 | 3;
  isLoading?: boolean;
  errorMessage?: string;
  events: SecurityEvent[];
  devices: ZoneDevice[];
  activeTypeFilter: FilterNamePlusAll;
} & JSX.IntrinsicElements['div'];

export const ShieldEvents: FunctionComponent<ShieldEventsProps> = ({
  columnSpan = 1,
  isLoading,
  errorMessage,
  events,
  devices,
  activeTypeFilter,
}) => {
  const now = new Date();
  const currentHour = now.getHours();
  const groupedEvents: SecurityEvent[][] = Array.from(Array(24), () => []);

  const { t } = useTranslation();
  const { setContent } = useSidepanel();
  const [cards, setCards] = useState<groupByFqdntype[]>();
  const dispatch = useDispatch<AppDispatch>();

  const typeFilteredEvents = useMemo(
    () => filterEvents(events || [], activeTypeFilter),
    [activeTypeFilter, events]
  );

  useEffect(() => {
    setCards(groupBySecurityKeyValue(typeFilteredEvents, 'fqdn', 'ipaddress'));
  }, [events, activeTypeFilter]);

  events.forEach((e) => {
    const hour = new Date(e.createdAt).getHours();
    const arraySlot =
      hour === currentHour ? currentHour - hour : 24 - hour + currentHour;
    groupedEvents[arraySlot]?.push(e);
  });

  const handleToggleCard = (cardIndex: number) => {
    if (!cards) {
      return;
    }
    const updatedCards = [...cards];
    updatedCards[cardIndex].isOpen = !updatedCards[cardIndex].isOpen;
    setCards(updatedCards);
  };

  const handleViewAllClick = useCallback(
    (
      events: SecurityEvent[],
      fqdn: string,
      index: number,
      ev: React.MouseEvent
    ) => {
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );

      setContent(
        <ViewMoreShieldEventsSidePanel
          cardIndex={index}
          header={fqdn}
          events={events}
          devices={devices}
          handleToggleOpen={() => handleToggleCard(index)}
        />
      );
    },
    [setContent, devices]
  );

  if (!!cards?.length) {
    return (
      <>
        <Grid columnCount={columnSpan}>
          {cards?.slice(0, SHIELD_EVENTS_CARD_LIMIT).map((card, index) => {
            return (
              <V2Card
                key={`${index}_${card.fqdn}`}
                isLoading={isLoading}
                errorMessage={errorMessage}
                className={cn(styles.eventsCard)}
                span={1}
                header={
                  <V2CardHeader
                    title={card.fqdn}
                    right={
                      <Body1 noWrap className={colorStyles.still400}>
                        {t('shield.eventsWithCount', {
                          eventCount: card.events.length,
                        })}
                      </Body1>
                    }
                  />
                }
                footer={
                  // card.events.length > SHIELD_EVENTS_PER_EVENTS_CARD_LIMIT
                  card.events.length > 2
                    ? {
                        label: t('common.viewMore'),
                        onClick: (ev: React.MouseEvent) => {
                          handleViewAllClick(card.events, card.fqdn, index, ev);
                        },
                        ariaLabel: t('shield.viewMoreAriaLabel'),
                      }
                    : undefined
                }
                collapsible
                defaultsToCollapsed
                noBottomPadding
              >
                <ShieldEventList
                  cardIndex={index}
                  events={card.events.slice(
                    0,
                    SHIELD_EVENTS_PER_EVENTS_CARD_LIMIT
                  )}
                  devices={devices}
                  handleToggleOpen={() => handleToggleCard(index)}
                />
              </V2Card>
            );
          })}
        </Grid>
      </>
    );
  }

  return null;
};
