import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'UI/Elements/Card';
import styles from './style.module.css';

import useEmployeeDailyDataUsage from 'State/hooks/useEmployeeDailyDataUsage';
import useEmployeeMonthlyDataUsage from 'State/hooks/useEmployeeMonthlyDataUsage';

import DailyDataUsageGraphUI from '../Data usage';

const EmployeeDataUsage: FunctionComponent = () => {
  const { t } = useTranslation();
  const dailyData = useEmployeeDailyDataUsage();
  const monthlyData = useEmployeeMonthlyDataUsage();

  const isLoading =
    (dailyData.isLoading && !dailyData.data) ||
    (monthlyData.isLoading && !monthlyData.data);
  const errorMessage = dailyData.errorMessage || monthlyData.errorMessage;

  if (isLoading || errorMessage) {
    return (
      <Card
        className={cn(styles.appTimeCard, {
          [styles.withPlaceholder]: errorMessage,
        })}
        isLoading={isLoading}
        errorMessage={errorMessage}
        header={{
          L2Props: { label: t('common.dataUsage') },
        }}
        emptyCardLabel={errorMessage}
      />
    );
  }

  return (
    <DailyDataUsageGraphUI daily={dailyData.data} monthly={monthlyData.data} />
  );
};

export default EmployeeDataUsage;
