import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SettingsSectionHeader from 'UI/Components/Headers/SettingsSectionHeader';

import V2ListItem from 'UI/Molecules/ListItem';
import { Body1, Body2 } from 'UI/Elements/Typography';
import Card from 'UI/Elements/Card';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import Divider from 'UI/Elements/Divider';

import type { AppDispatch } from 'State/store';
import * as selectors from 'State/selectors';
import * as actions from 'State/actions';

import { copyToClipboard } from 'Utils/string';

import styles from './style.module.css';
import colorStyles from 'Styles/colors.module.css';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';

const Support: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();

  const copy = useCallback(async () => {
    const res = await copyToClipboard(
      customerSupportConfigurations?.customerConfigurations
        ?.callUsPhoneNumber ||
        customerSupportConfigurations?.customerConfigurations
          ?.callUsDisplayPhoneNumber ||
        '1-844-MY-PLUME'
    );

    if (res) {
      dispatch(
        actions.ui.miniBanner.notify({
          label: t('settings.numberCopied'),
          state: 'set',
        })
      );
    }
  }, [dispatch, t, customerSupportConfigurations]);

  const uiVersion = useSelector(selectors.build.uiVersion);

  const faqEnabled =
    customerSupportConfigurations?.customerFeatureEnablement?.faqEnabled;
  const contactUsEnabled =
    customerSupportConfigurations?.customerFeatureEnablement?.contactUsEnabled;
  const callUsEnabled =
    customerSupportConfigurations?.customerFeatureEnablement?.callUsEnabled;
  const termsEnabled =
    customerSupportConfigurations?.customerFeatureEnablement?.termsEnabled;
  const manageYourDataEnabled =
    customerSupportConfigurations?.customerFeatureEnablement
      ?.manageYourDataEnabled;
  const privacyEnabled =
    customerSupportConfigurations?.customerFeatureEnablement?.privacyEnabled;

  return (
    <div
      className={styles.main}
      role="region"
      aria-labelledby={t('settings.support')}
    >
      <Card noBottomPadding>
        {faqEnabled && (
          <>
            <V2ListItem
              left={
                <Icon
                  name={IconNames.CircleQuestionMark}
                  shape={IconShape.square}
                />
              }
              main={<Body1>{t('settings.faq')}</Body1>}
              /* TODO open in an iframe */
              onClick={() =>
                window.open(
                  customerSupportConfigurations?.customerConfigurations
                    ?.faqUrl || 'https://support.plume.com/hc/en-us'
                )
              }
              ariaLabel={t('settings.faq')}
            />
          </>
        )}

        {contactUsEnabled && (
          <>
            {faqEnabled && <Divider />}
            <V2ListItem
              left={
                <Icon name={IconNames.EmailIcon} shape={IconShape.square} />
              }
              main={<Body1>{t('settings.contactUs')}</Body1>}
              onClick={() =>
                window.open(
                  'mailto:' +
                    customerSupportConfigurations?.customerConfigurations
                      ?.contactUsEmail || 'mailto:support@plume.com'
                )
              }
              ariaLabel={t('settings.contactUs')}
            />
          </>
        )}

        {callUsEnabled && (
          <>
            {(faqEnabled || contactUsEnabled) && <Divider />}
            <V2ListItem
              left={<Icon name={IconNames.Call} shape={IconShape.square} />}
              main={<Body1>{t('settings.callUs')}</Body1>}
              right={
                <Body2 className={colorStyles.still400}>
                  {customerSupportConfigurations?.customerConfigurations
                    ?.callUsDisplayPhoneNumber || '1-844-MY-PLUME'}
                </Body2>
              }
              onClick={copy}
              ariaLabel={t('settings.callUs')}
            />
          </>
        )}
      </Card>

      <div>
        <SettingsSectionHeader
          LProps={{ smallLabel: t('settings.aboutApp') }}
        />
        <Card noBottomPadding>
          <V2ListItem
            left={<Icon name={IconNames.CircleInfo} shape={IconShape.square} />}
            main={
              <Body1>{`${t('settings.appVersion')} ${
                uiVersion || 'unknown'
              }`}</Body1>
            }
            ariaLabel={t('settings.aboutApp')}
          />

          {termsEnabled && (
            <>
              <Divider />
              <V2ListItem
                left={
                  <Icon name={IconNames.CircleInfo} shape={IconShape.square} />
                }
                main={<Body1>{t('settings.terms')}</Body1>}
                right={
                  <Icon name={IconNames.OpenBrowser} shape={IconShape.square} />
                }
                onClick={() =>
                  window.open(
                    customerSupportConfigurations?.customerConfigurations
                      ?.termsUrl ||
                      'https://static.plume.com/terms/plume/terms.html'
                  )
                }
                ariaLabel={t('settings.terms')}
              />
            </>
          )}
          <Divider />
          <V2ListItem
            left={<Icon name={IconNames.CircleInfo} shape={IconShape.square} />}
            main={<Body1>{t('settings.trademarks')}</Body1>}
            right={
              <Icon name={IconNames.OpenBrowser} shape={IconShape.square} />
            }
            onClick={() =>
              window.open('https://www.plume.com/legal/trademarks')
            }
            ariaLabel={t('settings.trademarks')}
          />
          {manageYourDataEnabled && (
            <>
              <Divider />
              <V2ListItem
                left={
                  <Icon name={IconNames.CircleInfo} shape={IconShape.square} />
                }
                main={<Body1>{t('settings.yourPrivacyChoices')}</Body1>}
                right={
                  <Icon name={IconNames.OpenBrowser} shape={IconShape.square} />
                }
                onClick={() =>
                  window.open(
                    customerSupportConfigurations?.customerConfigurations
                      ?.manageYourDataUrl ||
                      'https://preferences.plume.com/dont_sell'
                  )
                }
                ariaLabel={t('settings.yourPrivacyChoices')}
              />
            </>
          )}

          {privacyEnabled && (
            <>
              <Divider />
              <V2ListItem
                left={
                  <Icon name={IconNames.CircleInfo} shape={IconShape.square} />
                }
                main={<Body1>{t('settings.privacy')}</Body1>}
                right={
                  <Icon name={IconNames.OpenBrowser} shape={IconShape.square} />
                }
                onClick={() =>
                  window.open(
                    customerSupportConfigurations?.customerConfigurations
                      ?.privacyUrl || 'https://www.plume.com/workpass/legal-sb'
                  )
                }
                ariaLabel={t('settings.privacy')}
              />
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Support;
