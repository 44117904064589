import { pubNubUserId } from 'Consts/config';
import PubNub from 'pubnub';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';
import { PubNubService } from '../services/pubNub.service';

export const useRealTimeNotificationSubscribe = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pubNubConfig = useSelector(selectors.pubNub);
  const activeLocation = useSelector(selectors.locations.activeLocation);

  const callback = (data: PubNub.SignalEvent) => {
    switch (data?.channel) {
      case `${activeLocation?.data?.id}.topologyAlert`:
        dispatch(actions.topology.fetchData());
        break;
      default:
        console.error('Unexpected event,', data);
    }
  };

  return () => {
    if (pubNubConfig.data) {
      const config = {
        authKey: pubNubConfig.data?.pubNub?.authKey,
        subscribeKey: '' + pubNubConfig.data?.pubNub?.subscribeKey,
        uuid: pubNubUserId(pubNubConfig.data?.locationId),
        ssl: true,
      };
      const token = pubNubConfig.data?.pubNub?.token;
      PubNubService.getInstance(config, token)?.addListener(
        [`${pubNubConfig.data?.locationId}.topologyAlert`],
        callback
      );
    }
  };
};
