import cn from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import CardHeader, { CardHeaderProps } from 'UI/Components/Headers/Header card';

import CardFooter, { Props as CardFooterProps } from 'UI/Elements/CardFooter';
import Loader from 'UI/Elements/Loader';

import colorStyles from 'Styles/colors.module.css';

import { Nullable } from '../../../Consts/types';
import { Body2 } from '../Typography';
import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type ContentProps = {
  isLoading?: boolean;
  errorMessage?: Nullable<string>;
  emptyCardLabel?: Nullable<string>;
  children?: React.ReactNode;
  footer?: CardFooterProps;
};

export type V2CardProps = {
  className?: string;
  children?: React.ReactNode;
  header?: React.ReactElement;
  dummy?: boolean;
  span?: 1 | 2 | 3;
  position?: 1 | 2 | 3;
  noBottomPadding?: boolean;
  onClick?: React.MouseEventHandler;
  collapsible?: boolean;
  defaultsToCollapsed?: boolean;
} & ContentProps;

export type CardProps = {
  className?: string;
  children?: React.ReactNode;
  header?: CardHeaderProps;
  dummy?: boolean;
  span?: 1 | 2 | 3;
  position?: 1 | 2 | 3;
  noBottomPadding?: boolean;
  isAlert?: boolean;
  ariaLabel?: string;
  ariaDescription?: string;
  onClick?: React.MouseEventHandler;
} & ContentProps;

const Content: FunctionComponent<ContentProps> = ({
  isLoading,
  emptyCardLabel,
  errorMessage,
  children,
  footer,
}) => {
  if (isLoading) {
    return (
      <div className={styles.loadingCardContent}>
        <Loader />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <Body2 className={cn(styles.loadingCardContent, colorStyles.sore500)}>
        {emptyCardLabel}
      </Body2>
    );
  }

  if (emptyCardLabel) {
    return (
      <>
        {children}
        <Body2 className={cn(styles.loadingCardContent, colorStyles.still400)}>
          {emptyCardLabel}
        </Body2>
      </>
    );
  }

  return (
    <>
      {children}

      {footer && <CardFooter {...footer} />}
    </>
  );
};

export const V2Card: FunctionComponent<V2CardProps> = ({
  dummy,
  span = 1,
  position,
  onClick,
  header,
  footer,
  noBottomPadding = false,
  emptyCardLabel,
  children,
  className,
  isLoading,
  errorMessage,
  collapsible = false,
  defaultsToCollapsed = false,
}) => {
  const [collapsed, setCollapsed] = useState(!!defaultsToCollapsed);

  const classNames = cn(
    styles.card,
    {
      [styles.withFooter]: !!footer,
      [styles.noBottomPadding]:
        noBottomPadding && (!collapsible || (!!collapsible && !collapsed)),
      [styles.dummy]: dummy,
      [styles.firstColumn]: position === 1,
      [styles.secondColumn]: position === 2,
      [styles.thirdColumn]: position === 3,
      [styles.span2]: span === 2,
      [styles.span3]: span === 3,
    },
    className
  );

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  const clonedHeader =
    header &&
    collapsible &&
    React.cloneElement(header, {
      isCollapsed: collapsed,
      onCollapseToggle: handleCollapseToggle,
    });

  return (
    <div
      className={classNames}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
    >
      {header && collapsible ? clonedHeader : header}

      {(!header || !collapsible || !collapsed) && (
        <Content
          isLoading={isLoading}
          errorMessage={errorMessage}
          emptyCardLabel={emptyCardLabel}
          footer={footer}
        >
          {children}
        </Content>
      )}
    </div>
  );
};

export const Card: FunctionComponent<CardProps> = ({
  dummy,
  span = 1,
  position,
  onClick,
  header,
  footer,
  noBottomPadding = false,
  emptyCardLabel,
  children,
  className,
  isLoading,
  errorMessage,
  isAlert,
  ariaLabel,
  ariaDescription,
}) => {
  const classNames = cn(
    styles.card,
    {
      [styles.noBottomPadding]: noBottomPadding,
      [styles.dummy]: dummy,
      [styles.firstColumn]: position === 1,
      [styles.secondColumn]: position === 2,
      [styles.thirdColumn]: position === 3,
      [styles.span2]: span === 2,
      [styles.span3]: span === 3,
    },
    className
  );

  return (
    <div
      className={classNames}
      onClick={onClick}
      role={isAlert ? 'dialog' : undefined}
      aria-modal={isAlert ? 'true' : 'false'}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
    >
      {header && <CardHeader {...header} />}

      <Content
        isLoading={isLoading}
        errorMessage={errorMessage}
        emptyCardLabel={emptyCardLabel}
        footer={footer}
      >
        {children}
      </Content>
    </div>
  );
};

export default Card;
