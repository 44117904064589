import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import Tooltip from 'UI/Elements/Tooltip';
import { generateId } from 'Utils/string';

import { BarGraphColors } from 'Consts/defintions';

import type { BarGraphData, GradientColors } from '../types';

import { CategoricalChartState } from 'recharts/types/chart/types';
import { roundNumber } from '../../../../Pages/Zones/utils';
import { isNumericValue } from '../../../../Utils/mbMath';
import styles from './style.module.css';

type AxisTickProps = {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
};

type GradientProps = GradientColors & { id: string };

export const XAxisTick: FunctionComponent<AxisTickProps> = ({
  x,
  y,
  payload,
}) => {
  if (x === undefined || y === undefined) {
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      dy={16}
      textAnchor="middle"
      fill={BarGraphColors.fill}
      fontSize={10}
    >
      {payload?.value?.split?.(' ').map((el: string, idx: number) => {
        return (
          <tspan key={el} x={x} y={y + 5} dy={idx * 15}>
            {el}
          </tspan>
        );
      })}
    </text>
  );
};

export const YAxisTick: FunctionComponent<AxisTickProps> = ({
  x,
  y,
  payload,
}) => {
  if (x === undefined || y === undefined) {
    return null;
  }
  const value =
    payload?.value && isNumericValue(payload.value)
      ? roundNumber(parseFloat(payload.value))
      : payload?.value;
  return (
    <text
      x={x - 5}
      y={y}
      width={0}
      dy={7}
      textAnchor="end"
      fill={BarGraphColors.fill}
      fontSize={10}
    >
      {value}
    </text>
  );
};

const DefaultTooltip: FunctionComponent<TooltipProps<string, string>> = ({
  label,
}) => {
  return <Tooltip>{label}</Tooltip>;
};

const Gradient: FunctionComponent<GradientProps> = ({ color1, color2, id }) => {
  return (
    <linearGradient
      id={id}
      x1="0"
      y1="0"
      x2="0"
      y2="100%"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor={color1} />
      <stop offset="1" stopColor={color2} />
    </linearGradient>
  );
};

export type BarGraphProps = {
  className?: string;
  YAxisWidth?: number;
  height?: number;
  data: BarGraphData<string>[];
  graphColors?: Record<string, string>;
  graphGradient?: GradientColors;
  customTooltip?: (props: TooltipProps<string, string>) => React.ReactNode;
  noYAxisDecimals?: boolean;
  interval?: number;
  ariaLabel: string;
};

const BarGraph: FunctionComponent<BarGraphProps> = ({
  className,
  data,
  graphGradient,
  graphColors,
  height = 165,
  customTooltip,
  noYAxisDecimals,
  interval = 0,
  YAxisWidth = 30,
  ariaLabel,
}) => {
  const [focusBarIndex, setFocusBarIndex] = useState<number | void>();

  const gradientId = useMemo(() => generateId(), []);
  const onMouseMove = useCallback((state: CategoricalChartState) => {
    if (state?.isTooltipActive) {
      setFocusBarIndex(state.activeTooltipIndex);
    } else {
      setFocusBarIndex();
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setFocusBarIndex();
  }, []);

  const keys = Object.keys(data?.[0]?.values || {});

  return (
    <div className={cn(styles.barGraph, className)} aria-label={ariaLabel}>
      <ResponsiveContainer height={height}>
        <BarChart
          className={styles.chart}
          data={data.map(({ name, values }) => ({ name, ...values }))}
          margin={{ top: 10, right: 3, left: 0, bottom: 10 }}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          accessibilityLayer
        >
          <defs>
            {graphGradient && <Gradient {...graphGradient} id={gradientId} />}
          </defs>

          <CartesianGrid />

          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={<XAxisTick />}
            interval={interval}
          />

          <YAxis
            tickLine={{ stroke: BarGraphColors.tickLine }}
            axisLine={false}
            fontSize={10}
            tick={<YAxisTick />}
            width={YAxisWidth}
            allowDecimals={!noYAxisDecimals}
          />

          <ChartTooltip
            cursor={{ fill: 'transparent' }}
            content={customTooltip || DefaultTooltip}
          />

          {keys.map((key, i) => {
            const radius: number | [number, number, number, number] =
              keys.length === 1
                ? [3, 3, 3, 3]
                : i === 0
                ? [0, 0, 3, 3]
                : i === keys.length - 1
                ? [3, 3, 0, 0]
                : 0;

            return (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={
                  graphGradient ? `url(#${gradientId})` : graphColors?.[key]
                }
                barSize={5}
                radius={radius}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    className={cn({
                      [styles.transparentBar]:
                        focusBarIndex !== undefined && focusBarIndex !== index,
                    })}
                  />
                ))}
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
