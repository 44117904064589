import cn from 'classnames';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Guest, Nullable, OpenTriggerEventType } from 'Consts/types';

import ListItem from 'UI/Components/Lists/List standard';

import Card from 'UI/Elements/Card';
import Divider from 'UI/Elements/Divider';
import { IconNames } from 'UI/Elements/Icon';
import SegmentedControls from 'UI/Elements/SegmentedControls';

import {
  SidePanelContentWrapper,
  SimpleSidePanel,
  SimpleSidePanelProps,
} from 'UI/Layout/Side panel';

import { formatRelativeToNow } from 'Utils/formatters/date';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { displayBytes } from 'Utils/mbMath';
import { formatOrdinals } from 'Utils/numberFormat';

import useGuests from 'State/hooks/useGuests';

import colorStyles from 'Styles/colors.module.css';

import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import useLocations from 'State/hooks/useLocations';
import Alert from 'UI/Components/Alert';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconShape } from 'UI/Elements/Icon/icons';
import AlertModal from 'UI/Elements/Modals/Alerts';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { useGuestDownloadStateMachine } from 'Utils/hooks/useGuestDownloadStateMachine';
import styles from './style.module.css';
import * as actions from 'State/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';

type GuestListProps = {
  guests: Guest[];
  sort?: 'recent' | 'active';
  clearExpanded?: boolean;
};

type GuestSidePanel = {
  guests: Guest[];
  showDownloadButtonForLocationOwner: boolean;
  handleIconClick: () => void;
} & SimpleSidePanelProps;

type GuestsProps = {
  maxItems: number;
} & JSX.IntrinsicElements['div'];

function matchSocialIcon(social?: string) {
  switch (social) {
    case 'Facebook':
      return IconNames.SocialFacebook;

    case 'Email':
      return IconNames.SocialEmail;

    case 'PassCode':
      return IconNames.SocialPassword;

    case 'SMS':
      return IconNames.SocialSMS;

    default:
      return IconNames.SocialWifi;
  }
}

const sortGuests = (guests: Guest[]): Guest[] => {
  return [...guests].sort(
    (g1, g2) =>
      (g2.rxBytes ?? 0) +
      (g2.txBytes ?? 0) -
      ((g1.rxBytes ?? 0) + (g1.txBytes ?? 0))
  );
};

const GuestList: FunctionComponent<GuestListProps> = ({
  guests,
  sort = 'recent',
  // TODO add clear expaned on click away, switch tab etc
}) => {
  const [expanded, setExpanded] = useState<number | null>(null);
  const { t } = useTranslation();

  const noOfGuests = guests.length;

  const sortedGuests = useMemo(() => {
    if (sort === 'recent') {
      return guests;
    }

    return sortGuests(guests);
  }, [sort, guests]);

  const handleGuestClick = (index: number) =>
    setExpanded((oldIndex) => (oldIndex === index ? null : index));

  return (
    <>
      {sortedGuests.map(
        (
          {
            loginType,
            fullName,
            mac,

            connectionState,
            connectionStateChangeAt,

            rxBytes,
            txBytes,

            deviceType: { name: deviceName },

            thirtyDayVisitCount,
          },
          i
        ) => {
          const isExpanded = expanded === i;
          const iconName = matchSocialIcon(loginType);

          const lastOnline =
            connectionState === 'connected'
              ? t('common.online')
              : formatRelativeToNow(new Date(connectionStateChangeAt));

          const rProps = isExpanded
            ? { icon1Props: { name: IconNames.Profile40Generic01 } }
            : {
                label:
                  sort === 'recent'
                    ? lastOnline
                    : displayBytes((rxBytes ?? 0) + (txBytes ?? 0)),
              };

          return (
            <Fragment key={`${mac}_${i}`}>
              <ListItem
                className={cn({ [styles.listItemExpanded]: isExpanded })}
                // TODO use photo
                L1Props={
                  iconName
                    ? {
                        iconProps: {
                          name: iconName,
                          className: styles.socialIcons,
                        },
                      }
                    : {}
                }
                L2Props={{
                  // TODO fix color
                  className: cn({ [colorStyles.still800]: isExpanded }),
                  label: fullName || deviceName,
                  ...(isExpanded
                    ? {
                        paragraph: `${deviceName}, ${lastOnline}`,
                        secondParagraph:
                          t('homepage.last30DaysVisit', {
                            time: formatOrdinals(thirtyDayVisitCount),
                          }) || '',
                      }
                    : {
                        paragraph: deviceName,
                      }),
                }}
                RProps={{
                  ...rProps,
                  className: cn(colorStyles.still400, {
                    [styles.online]:
                      connectionState === 'connected' && sort === 'recent',
                  }),
                }}
                onClick={() => handleGuestClick(i)}
                ariaLabel={fullName || deviceName}
              />

              {i < noOfGuests - 1 && <Divider key={i} />}
            </Fragment>
          );
        }
      )}
    </>
  );
};

const GuestSidePanelUI: FunctionComponent<GuestSidePanel> = ({
  guests,
  title,
  showDownloadButtonForLocationOwner = true,
  handleIconClick,
}) => {
  const [sort, setSort] = useState<'recent' | 'active'>('recent');
  const { t } = useTranslation();

  const onTabClick = useCallback((index: number) => {
    if (index === 1) {
      setSort('active');
    } else {
      setSort('recent');
    }
  }, []);

  const tabs = [
    { label: t('homepage.mostRecent') },
    { label: t('homepage.mostActive') },
  ];

  return (
    <SimpleSidePanel
      title={title}
      rightIconProps={
        showDownloadButtonForLocationOwner
          ? {
              name: IconNames.ArrowDownStop,
              onClick: () => handleIconClick(),
              shape: IconShape.square,
              tooltipLabel: t('guestZone.downloadButton'),
            }
          : undefined
      }
    >
      <SegmentedControls tabs={tabs} onClick={onTabClick} />
      <SidePanelContentWrapper>
        <Card>
          <GuestList guests={guests} sort={sort} />
        </Card>
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

type GuestsUIProps = {
  isLoading?: boolean;
  errorMessage?: string;
  guests: Guest[];
  title?: Nullable<string>;
  maxItems?: number;
} & JSX.IntrinsicElements['div'];

export const GuestsUI: FunctionComponent<GuestsUIProps> = ({
  className,
  title,
  isLoading,
  errorMessage,
  guests,
  maxItems = 5,
}) => {
  const { closeSidepanel, setContent } = useSidepanel();
  const {
    checkForDownload,
    handleDownloadGuests,
    handleSetEmailCapture,
    isDownloadAlertOpen,
    isEmailCaptureAlertOpen,
    isNoEmailsToDownloadAlertOpen,
    closeDownloadAlert,
    closeEmailCaptureAlert,
    closeNoEmailsToDownloadAlert,
  } = useGuestDownloadStateMachine();
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const dispatch = useDispatch<AppDispatch>();

  const { activeLocation } = useLocations();

  const showDownloadButtonForLocationOwner =
    activeLocation.data?.accessedAsCoAdminLocation !== true &&
    !customerSupportConfigurations?.customerFeatureEnablement
      .captivePortalGuestEmailDownloadDisabled;

  const viewAllOnClick = useCallback(
    (ev: React.MouseEvent) => {
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );

      setContent(
        <GuestSidePanelUI
          guests={guests}
          showDownloadButtonForLocationOwner={
            showDownloadButtonForLocationOwner
          }
          title={t('common.guests')}
          handleIconClick={checkForDownload}
        />
      );
    },
    [checkForDownload, isEmailCaptureAlertOpen]
  );

  const noOfGuests = guests.length;
  const showIsViewMore =
    noOfGuests > 0
      ? {
          label: t('common.viewMore'),
          ariaLabel: t('common.viewMoreAriaLabel', { label: title }),
          onClick: viewAllOnClick,
        }
      : undefined;

  return (
    <>
      <AlertModal isOpen={isDownloadAlertOpen} onClose={closeDownloadAlert}>
        <Alert
          topProps={{ label: t('guestZone.download.title') }}
          className={styles.title}
          bottomProps={{
            button1Props: {
              label: t('common.yes'),
              onClick: handleDownloadGuests,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: closeDownloadAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>
      <AlertModal
        isOpen={isEmailCaptureAlertOpen}
        onClose={closeEmailCaptureAlert}
      >
        <Alert
          topProps={{ label: t('guestZone.download.emailCaptureTitle') }}
          className={styles.title}
          bottomProps={{
            button1Props: {
              label: t('common.turnOn'),
              onClick: () => {
                handleSetEmailCapture();
                closeSidepanel();
              },
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: closeEmailCaptureAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>
      <AlertModal
        isOpen={isNoEmailsToDownloadAlertOpen}
        onClose={closeNoEmailsToDownloadAlert}
      >
        <Alert
          topProps={{
            label: t('guestZone.download.noEmailsToDownloadTitle'),
            paragraph:
              t('guestZone.download.noEmailsToDownloadBody', {
                ns: namespace,
              }) || '',
          }}
          bottomProps={{
            button1Props: {
              label: t('common.iUnderstand'),
              onClick: closeNoEmailsToDownloadAlert,
            },
          }}
        />
      </AlertModal>
      <Card
        className={className}
        isLoading={isLoading}
        errorMessage={errorMessage}
        header={{
          L2Props: {
            label: title || t('common.guests'),
            secondLabel: noOfGuests && !isLoading ? noOfGuests : '',
            paragraph: noOfGuests === 0 ? t('common.noGuest') : '',
          },
        }}
        footer={showIsViewMore}
        noBottomPadding
      >
        <GuestList guests={guests.slice(0, maxItems)} />
        {showDownloadButtonForLocationOwner && (
          <div className={styles.btn}>
            <Button
              prefixIcon={IconNames.ArrowDownStop}
              label={t('guestZone.downloadButton')}
              theme={BUTTON_THEMES.white}
              onClick={() => checkForDownload()}
              fullWidth
            />
          </div>
        )}
      </Card>
    </>
  );
};

export const Guests: FunctionComponent<GuestsProps> = ({
  className,
  maxItems,
}) => {
  const guests = useGuests();
  const { t } = useTranslation();

  return (
    <GuestsUI
      className={className}
      maxItems={maxItems}
      isLoading={guests.isLoading}
      errorMessage={guests.errorMessage}
      guests={guests.data ?? []}
      title={t('common.guests') as string}
    />
  );
};

export default Guests;
