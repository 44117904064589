import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import PillWeb from 'UI/Elements/Pill web';
import { IconNames } from 'UI/Elements/Icon';
import Menu, { MenuItemProps } from 'UI/Components/Menu';

import styles from './style.module.css';
import { Nullable } from '../../../Consts/types';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

type FiltersProps = {
  isMenuOpen: boolean;
  menuItems: MenuItemProps[];
  parent: Nullable<Element>;
  handleClick: (ev: React.MouseEvent, item: string) => void;
  handleOnClose: () => void;
  filters?: FilterHeading[];
  maxHeight?: number;
  menuOpenTrigger: MenuOpenTriggerEventType | undefined;
} & JSX.IntrinsicElements['div'];

export type FilterHeading = {
  label: string;
  filterKey: string;
};

const Filters: FunctionComponent<FiltersProps> = ({
  handleClick,
  handleOnClose,
  isMenuOpen,
  menuItems,
  parent,
  filters,
  className,
  maxHeight,
  menuOpenTrigger,
}) => {
  const { t } = useTranslation();
  const DEVICE_FILTERS = [
    { label: t('devices.sort'), filterKey: 'Sort' },
    { label: t('devices.group'), filterKey: 'Group' },
  ];
  const filterItems = filters || DEVICE_FILTERS;
  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filterBlock}>
        {filterItems.map((filter) => (
          <PillWeb
            className={className}
            key={filter.filterKey}
            label={filter.label}
            iconProps={{ name: IconNames.ChevronDown, className: styles.icon }}
            onClick={(ev) => {
              handleClick(ev, filter.filterKey);
            }}
          />
        ))}
      </div>

      <Menu
        className={styles.animation}
        isOpen={isMenuOpen}
        parent={parent}
        items={menuItems}
        onClose={handleOnClose}
        maxHeight={maxHeight}
        openTriggerEventType={menuOpenTrigger}
      />
    </div>
  );
};

export default Filters;
