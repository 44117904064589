import { HealthColors } from 'Consts/defintions';
import { environments } from 'Consts/environments';
import {
  NetworkType,
  TopologyDevice,
  TopologyEdge,
  TopologyPod,
  TopologyVertice,
} from 'Consts/types';
import useTopology from 'State/hooks/useTopology';
import * as selectors from 'State/selectors';
import bellColors from 'Styles/bellColors';
import colors from 'Styles/colors';
import Loader from 'UI/Elements/Loader';
import { Body1 } from 'UI/Elements/Typography';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import useIsMobile from 'Utils/hooks/useIsMobile';
import useNavigateToDevice from 'Utils/hooks/useNavigateToDevice';
import useNavigateToPod from 'Utils/hooks/useNavigateToPod';
import { getDeviceIconFileName } from 'Utils/icons';
import { getMetasaurusNodeIconUrl } from 'Utils/metasaurusUtils';
import { forceCollide, forceX, forceY } from 'd3-force-3d';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ForceGraph2D, {
  ForceGraphMethods,
  NodeObject,
} from 'react-force-graph-2d';
import { useSelector } from 'react-redux';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import styles from './style.module.css';

type ForceGraphNode = (TopologyDevice | TopologyPod) & NodeObject;

type NodeImage = {
  label: string;
  img: HTMLImageElement;
};

type NetworkFilter = NetworkType | 'all';

type TopologyGraphProps = {
  network?: NetworkFilter;
};

const BELL = 'bell';

const TopologyGraph: FunctionComponent<TopologyGraphProps> = ({ network }) => {
  const fgRef = useRef<ForceGraphMethods>();
  const { data: topology, isLoading } = useTopology();
  const [deviceImages, setDeviceImages] = useState<NodeImage[]>();
  const [alreadyZoomed, setAlreadyZoomed] = useState(false);
  const navPanelHidden = useSelector(selectors.ui.page.navPanelHidden);
  const navigateToDevice = useNavigateToDevice();
  const navigateToPod = useNavigateToPod();
  const namespace = useCspTranslationNamespace();
  const isMobile = useIsMobile();
  const MAX_CONTENT_WIDTH = 1666;
  const environment = getConfigurationFromDomain();
  const cloud = useSelector(selectors.auth.cloud);

  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  const nodePaint = (
    node: ForceGraphNode,
    color: string,
    ctx: CanvasRenderingContext2D,
    isGateway: boolean
  ) => {
    ctx.fillStyle = color;

    const gw_badge = new Image();
    gw_badge.src = '/globe.svg';

    // WARNING on Node 18 - https://github.com/vasturiano/react-force-graph/issues/409
    if (typeof node.x === 'number' && typeof node.y === 'number') {
      if (node.type === 'pod') {
        ctx.beginPath();
        ctx.arc(node.x, node.y, 8, 0, 2 * Math.PI, false);
        ctx.fill();
        const image = getImageFromLabel(node.metadata.model);
        const ratioH = image.naturalHeight / image.naturalWidth;
        const ratioW = image.naturalWidth / image.naturalHeight;
        ctx.drawImage(
          image,
          node.x - Math.min((ratioW * 10) / 2, 5),
          node.y - Math.min((ratioH * 10) / 2, 5),
          Math.min(ratioW * 10, 10),
          Math.min(ratioH * 10, 10)
        );
        if (isGateway) {
          ctx.drawImage(gw_badge, node.x + 2, node.y - 9, 6, 6); // Globe
        }
      } else {
        ctx.beginPath();
        ctx.arc(node.x, node.y, 6, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.drawImage(
          getImageFromLabel(node.metadata.iconV2),
          node.x - 8,
          node.y - 8,
          16,
          16
        );
      }

      ctx.textAlign = 'center';
      ctx.font = '3px Arial';
      ctx.fillStyle = 'black';
      ctx.fillText(node.label, node.x, node.y + 11);
    }
  };

  const getImageFromLabel = (type: string): HTMLImageElement => {
    if (deviceImages) {
      const firstDevice = deviceImages.filter(
        (element) => element.label === type
      );

      if (firstDevice.length) return firstDevice?.[0].img;
    }

    return new Image();
  };

  // Simulation effect
  useEffect(() => {
    if (topology) {
      fgRef.current?.d3Force('charge')?.strength(-100);
      fgRef.current?.d3Force('x', forceX().strength(0.1));
      fgRef.current?.d3Force('y', forceY().strength(0.1));
      fgRef.current?.d3Force('collide', forceCollide().radius(9));
      if (alreadyZoomed) {
        setAlreadyZoomed(false);
      }

      const images = topology.vertices.map((node: TopologyVertice) => {
        if (node.type === 'device') {
          const img = new Image();

          img.src = `/devices/iot-medium/${getDeviceIconFileName(
            node.metadata?.iconV2
          )}`;

          return { label: node.metadata?.iconV2, img };
        } else {
          const img = new Image();

          getMetasaurusNodeIconUrl(cloud, node.metadata?.model)
            .then((svgUrl: string) => {
              img.src = svgUrl;
              fgRef.current?.d3ReheatSimulation();
            })
            .catch(
              () =>
                (img.src =
                  environments[cloud]?.metasaurusUrl +
                  '/v2/en-us/nodes/Plume Pod v1.0/light.svg')
            );

          return { label: node.metadata?.model, img };
        }
      });

      if (images) setDeviceImages(images);
    }
  }, [topology]);

  const genTreeFromStore = useMemo(() => {
    if (!topology) return;
    const filteredVertices =
      network === 'all'
        ? topology.vertices
        : topology.vertices.filter(
            (element: TopologyVertice) =>
              element.type === 'pod' ||
              (element.type === 'device' &&
                element.metadata?.networkId === network)
          );
    const filteredLinks =
      network === 'all'
        ? topology.edges
        : topology.edges.filter(
            (linksElement: TopologyEdge) =>
              filteredVertices.findIndex(
                (element: TopologyVertice) => element.id === linksElement.target
              ) >= 0
          );

    return {
      nodes: cloneDeep(filteredVertices),
      links: cloneDeep(filteredLinks),
    };
  }, [network, topology]);

  const navigateToDeviceOrNode = (node: NodeObject) => {
    const item = node as ForceGraphNode;
    if (item.type === 'pod') {
      if (node.id) {
        navigateToPod(node.id?.toString());
      }
    } else {
      navigateToDevice(item.id);
    }
  };

  const getNodeColor = (node: TopologyPod | TopologyDevice): string => {
    if (node.type === 'pod')
      return (
        HealthColors[node?.health?.status] ||
        (isBell ? bellColors.still300 : colors.still300)
      );
    else return isBell ? bellColors.still100 : colors.still100;
  };

  const isGateway = (node: TopologyPod | TopologyDevice): boolean => {
    if (node.type === 'pod')
      return (
        node.connectionState === 'connected' &&
        node.metadata.backhaulType === 'ethernet'
      );
    else return false;
  };

  return (
    <>
      {isLoading && !topology?.vertices?.length && (
        <div
          className={styles.loaderWrapper}
          role="alert"
          aria-live="assertive"
        >
          <Loader />
        </div>
      )}
      {topology?.vertices?.length ? (
        <section aria-label={t('common.topologyGraph', { ns: namespace })}>
          <ForceGraph2D
            graphData={genTreeFromStore}
            width={Math.min(
              window.innerWidth - (isMobile || navPanelHidden ? 40 : 320),
              MAX_CONTENT_WIDTH
            )}
            height={window.innerHeight - 150}
            nodeLabel="label"
            linkWidth={5}
            linkColor="black"
            maxZoom={7}
            minZoom={1}
            ref={fgRef}
            onNodeClick={(node) => navigateToDeviceOrNode(node)}
            cooldownTicks={50}
            warmupTicks={150}
            nodeCanvasObjectMode={() => 'replace'}
            nodeCanvasObject={(item, ctx) =>
              nodePaint(
                item as ForceGraphNode,
                getNodeColor(item as TopologyPod),
                ctx,
                isGateway(item as TopologyPod)
              )
            }
            onEngineStop={() => {
              if (!alreadyZoomed) {
                fgRef?.current?.zoomToFit(
                  100,
                  isMobile || navPanelHidden ? 30 : 70
                );
                setAlreadyZoomed(true);
              }
            }}
          />
        </section>
      ) : (
        <>
          {!isLoading && (
            <div
              className={styles.emptyStateContent}
              role="status"
              aria-live="polite"
            >
              <Body1 className={styles.emptyStateText}>
                {t('common.noPods', { ns: namespace })}
              </Body1>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TopologyGraph;
