import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ITEM_LIMIT_PER_CARD_COLUMN } from 'Consts/config';
import { ROUTES } from 'Consts/routes';

import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import PageHeader from 'UI/Layout/Page header';
import SectionHeader from 'UI/Components/Headers/Header section';

import EmployeesAtWork from 'UI/Reusable/Employees at work';

import useDevices from 'State/hooks/useDevices';
import useDeviceFilters from 'State/hooks/useDeviceFilters';

import useLayoutColumns from 'Utils/hooks/useLayout';

import ApprovedDevicesCards from '../Components/Devices/Approved devices';
import UnapprovedDevicesCard from '../Components/Devices/Unapproved devices';
import BlockedDevicesCard from '../Components/Devices/Blocked devices';
import { useMenuItems } from '../Components/Devices/Device menu items';
import { View } from '../Components/Devices/types';

import EmployeeList from '../Components/Employees';
import EmployeeDataUsage from '../Components/Employee data usage';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import AddEmployeeSidePanel from 'UI/Reusable/AddEmployee/AddEmployee';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import Columns from 'UI/Layout/Columns';

import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { sortDevices } from '../Device management view all/utils';
import { OpenTriggerEventType, ZoneDevice } from 'Consts/types';
import QuarantinedDevicesCard from '../Components/Devices/Quarantined devices/QuarantinedDevicesCard';
import { AppTimeEmployeeZone } from '../Components/Employees/Components/AppTime';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import useLocationCapabilities from 'State/hooks/useLocationCapabilities';
import useEmployeeLoginDatabase from 'State/hooks/useEmployeeLoginDatabase';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';

export const Employee: FunctionComponent = () => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const navigate = useNavigate();
  const [columns, ref] = useLayoutColumns();
  const { setContent } = useSidepanel();
  const trackEvent = useTrackEvent();
  const { sortingType } = useDeviceFilters();
  const {
    allDevices: { isLoading, errorMessage },
    employeeDevices,
  } = useDevices();
  const dispatch = useDispatch<AppDispatch>();

  const { MENU_ITEMS } = useMenuItems({});

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const employeeLoginIsAvailableToCustomer =
    customerSupportConfigurations?.customerFeatureEnablement
      .employeeOnboardingCaptivePortalEnabled;

  const { data: locationCapabilitiesData } = useLocationCapabilities();
  const employeeLoginIsSupportedInHardware =
    locationCapabilitiesData?.state.capabilities?.captivePortalV2;

  const { data: employeeLoginDatabaseData } = useEmployeeLoginDatabase();
  const employeeLoginToggleIsEnabled =
    employeeLoginDatabaseData?.databaseData.enabled;

  const employeeLoginFeatureIsSupportedAndEnabled = useMemo(() => {
    return (
      employeeLoginToggleIsEnabled &&
      employeeLoginIsSupportedInHardware &&
      employeeLoginIsAvailableToCustomer
    );
  }, [
    employeeLoginToggleIsEnabled,
    employeeLoginIsSupportedInHardware,
    employeeLoginIsAvailableToCustomer,
  ]);

  const handleDeviceCardViewAllClick = useCallback(
    (view: View) => {
      navigate(ROUTES.zones.employee[view]);
    },
    [navigate]
  );

  const sortedUnapprovedDevices = sortDevices(
    employeeDevices?.auto || [],
    sortingType
  ) as ZoneDevice[];
  const sortedApprovedDevices = sortDevices(
    employeeDevices?.approved || [],
    sortingType
  ) as ZoneDevice[];
  const sortedBlockedDevices = sortDevices(
    employeeDevices?.blocked || [],
    sortingType
  ) as ZoneDevice[];
  const sortedQuarantinedDevices = sortDevices(
    employeeDevices?.quarantined || [],
    sortingType
  ) as ZoneDevice[];

  const unapprovedDevicesCard = !!sortedUnapprovedDevices.length && (
    <UnapprovedDevicesCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={sortedUnapprovedDevices}
      columnSpan={1}
      itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
      onViewAllClick={handleDeviceCardViewAllClick}
      headerMenuItems={[MENU_ITEMS.approveDevices, MENU_ITEMS.blockDevices]}
      menuItems={[
        MENU_ITEMS.assignDevice,
        MENU_ITEMS.approveDevice,
        MENU_ITEMS.deviceSettings,

        MENU_ITEMS.macAndIPAddress,
        MENU_ITEMS.blockDevice,
      ]}
      infoTooltipLabel={t('devices.unapprovedTooltipEmployee', {
        ns: namespace,
      })}
    />
  );

  const approvedDevicesCards = (
    <ApprovedDevicesCards
      isLoading={isLoading}
      errorMessage={errorMessage}
      columnSpan={1}
      itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
      devices={sortedApprovedDevices}
      isEmployeeZone={true}
      onViewAllClick={handleDeviceCardViewAllClick}
      headerMenuItems={[
        ...(sortedApprovedDevices.length ? [MENU_ITEMS.blockDevices] : []),
      ]}
      menuItems={[
        MENU_ITEMS.assignDevice,
        MENU_ITEMS.deviceSettings,
        MENU_ITEMS.macAndIPAddress,
        MENU_ITEMS.deviceShareAccess,
        MENU_ITEMS.blockDevice,
      ]}
      infoTooltipLabel={t('devices.approvedTooltipEmployee', { ns: namespace })}
    />
  );

  const blockedDevicesCard = !!sortedBlockedDevices.length && (
    <BlockedDevicesCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={sortedBlockedDevices}
      columnSpan={1}
      itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
      onViewAllClick={handleDeviceCardViewAllClick}
      headerMenuItems={[MENU_ITEMS.approveDevices]}
      menuItems={[
        MENU_ITEMS.assignDevice,
        MENU_ITEMS.approveDevice,
        MENU_ITEMS.deviceSettings,
        MENU_ITEMS.macAndIPAddress,
      ]}
    />
  );

  const quarantinedDevicesCard = !!sortedQuarantinedDevices.length && (
    <QuarantinedDevicesCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={sortedQuarantinedDevices}
      columnSpan={1}
      itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
      onViewAllClick={handleDeviceCardViewAllClick}
      headerMenuItems={[
        MENU_ITEMS.unquarantine1hr,
        MENU_ITEMS.unquarantinePermanently,
      ]}
      menuItems={[
        MENU_ITEMS.unquarantine1hr,
        MENU_ITEMS.unquarantinePermanently,
        MENU_ITEMS.deviceSettings,
        MENU_ITEMS.macAndIPAddress,
      ]}
    />
  );

  const handleAddEmployee = useCallback(
    (ev: React.MouseEvent) => {
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<AddEmployeeSidePanel />);
    },
    [setContent]
  );

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.EmployeeZone,
      },
    });
  }, []);

  return (
    <Page>
      <Background ref={ref}>
        <PageHeader
          label={t('common.employeeZone')}
          buttonPillProps={
            employeeLoginFeatureIsSupportedAndEnabled
              ? undefined
              : {
                  label: t('common.addEmployee'),
                  onClick: handleAddEmployee,
                }
          }
        />

        <SectionHeader label={t('secureZone.overview')} />
        <Columns columnCount={columns}>
          <EmployeesAtWork
            employeeLoginFeatureIsSupportedAndEnabled={
              employeeLoginFeatureIsSupportedAndEnabled
            }
          />
          <AppTimeEmployeeZone maxItems={6} />
          <EmployeeDataUsage />
        </Columns>

        <SectionHeader label={t('employeeZone.employees')} />
        <Columns columnCount={columns}>
          <EmployeeList
            warnAboutManualCreation={
              employeeLoginIsAvailableToCustomer &&
              employeeLoginIsSupportedInHardware &&
              employeeLoginToggleIsEnabled
            }
          />
        </Columns>

        <SectionHeader label={t('secureZone.devices')} />
        {!isLoading && (
          <Columns columnCount={columns}>
            {unapprovedDevicesCard}
            {approvedDevicesCards}
            {blockedDevicesCard}
            {quarantinedDevicesCard}
          </Columns>
        )}
      </Background>
    </Page>
  );
};
